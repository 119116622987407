import { Box, Dialog, DialogContent, Modal } from '@mui/material';
import { useRouter } from 'next/router';
import * as React from 'react';

import ModalContent from './ModalContent';
import { getPerformanceLabPagePaths } from 'helpers/getPerformanceLabPages';

export interface CastOptions {
  url: string;
}

interface Props extends CastOptions {
  open: boolean;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: () => void;
}
const customStyles = {
  dialog: {
    width: '100%',
    '& .MuiPaper-root': {
      maxWidth: '100%',
    },
  },
  dialogContent: {
    width: 1100,
    maxWidth: 1140,
  },
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxInner: {
    width: '100%',
    maxWidth: '1140px',
  },
};

const CastModal = ({ url, open, onClose, onClosed, onSubmit }: Props) => {
  const router = useRouter();
  const isPerformanceLabPage = getPerformanceLabPagePaths().includes(
    router.pathname
  );
  // modal when called from a performance lab page
  if (isPerformanceLabPage) {
    return (
      <Modal open={open} onClose={onClosed}>
        <Box sx={customStyles.box}>
          <Box sx={customStyles.boxInner}>
            <ModalContent
              onClose={onClose}
              url={url}
              onSubmit={onSubmit}
              isPerformanceLabPage={true}
            />
          </Box>
        </Box>
      </Modal>
    );
  } else {
    // modal when called from a non performance lab page
    return (
      <Dialog open={open} onClose={onClosed} sx={customStyles.dialog}>
        <DialogContent sx={customStyles.dialogContent}>
          <ModalContent
            onClose={onClose}
            url={url}
            onSubmit={onSubmit}
            isPerformanceLabPage={false}
          />
        </DialogContent>
      </Dialog>
    );
  }
};

export default CastModal;
