import { Box, Button, Typography, useTheme } from '@mui/material';
import { useRouter } from 'next/router';

interface Props {
  error: Error;
}

const customStyles = {
  page: {
    display: 'grid',
    placeItems: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  errorTitle: {
    mb: 1,
    color: 'black',
  },
  errorDescription: {
    color: 'black',
  },
  goBackButton: {
    border: '1px solid black',
    color: 'black',
  },
};

const ErrorFallback = ({ error }: Props) => {
  const router = useRouter();
  const theme = useTheme();

  return (
    <Box sx={customStyles.page}>
      <Box>
        <Typography
          variant={theme.palette.mode === 'light' ? 'h2' : 'h1'}
          sx={customStyles.errorTitle}
        >
          Client Side Error
        </Typography>

        <pre style={customStyles.errorDescription}>{error.message}</pre>

        <Button
          variant="secondary"
          sx={customStyles.goBackButton}
          onClick={router.back}
        >
          Go back
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorFallback;
