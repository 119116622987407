const countriesJSON = {
  items: [
    {
      id: 1,
      name: 'England',
      flag: {
        mediaType: 1,
        path: 'country/f1566d65-1508-49be-b0d5-3d01df5eed02',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/f1566d65-1508-49be-b0d5-3d01df5eed02',
      },
    },
    {
      id: 2,
      name: 'Republic of Ireland',
      flag: {
        mediaType: 1,
        path: 'country/8ad8c9bf-2c44-481b-bb36-25c4fd25d661',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/8ad8c9bf-2c44-481b-bb36-25c4fd25d661',
      },
    },
    {
      id: 3,
      name: 'Northern Ireland',
      flag: {
        mediaType: 1,
        path: 'country/74e6cccb-ec9e-4850-b511-8f99de94eeec',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/74e6cccb-ec9e-4850-b511-8f99de94eeec',
      },
    },
    {
      id: 4,
      name: 'Scotland',
      flag: {
        mediaType: 1,
        path: 'country/844e0a9a-5bba-4e03-881b-4dfaeebf3077',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/844e0a9a-5bba-4e03-881b-4dfaeebf3077',
      },
    },
    {
      id: 5,
      name: 'Wales',
      flag: {
        mediaType: 1,
        path: 'country/fb183a8a-7723-4276-8dc9-347b989638f9',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/fb183a8a-7723-4276-8dc9-347b989638f9',
      },
    },
    {
      id: 206,
      name: 'United States',
      flag: {
        mediaType: 1,
        path: 'country/264ed7b8-e863-4a81-bee9-508ce7a24905',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/264ed7b8-e863-4a81-bee9-508ce7a24905',
      },
    },
    {
      id: 217,
      name: 'Afghanistan',
      flag: {
        mediaType: 1,
        path: 'country/20abbac6-3e14-4f47-a462-c8912edfe482',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/20abbac6-3e14-4f47-a462-c8912edfe482',
      },
    },
    {
      id: 218,
      name: 'Albania',
      flag: {
        mediaType: 1,
        path: 'country/d7370f6e-f009-4d6f-879d-efec0ac62162',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d7370f6e-f009-4d6f-879d-efec0ac62162',
      },
    },
    {
      id: 219,
      name: 'Algeria',
      flag: {
        mediaType: 1,
        path: 'country/17a4ccb7-ffbc-40b4-ab8f-093d734398d8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/17a4ccb7-ffbc-40b4-ab8f-093d734398d8',
      },
    },
    {
      id: 220,
      name: 'American Samoa',
      flag: {
        mediaType: 1,
        path: 'country/bf04441c-70c1-4600-b87f-89c6de186952',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/bf04441c-70c1-4600-b87f-89c6de186952',
      },
    },
    {
      id: 221,
      name: 'Andorra',
      flag: {
        mediaType: 1,
        path: 'country/4896f833-b404-4f2e-a5c5-eae19efaa089',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/4896f833-b404-4f2e-a5c5-eae19efaa089',
      },
    },
    {
      id: 222,
      name: 'Angola',
      flag: {
        mediaType: 1,
        path: 'country/e02f9a46-f432-41f4-92ca-e569b2348cca',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e02f9a46-f432-41f4-92ca-e569b2348cca',
      },
    },
    {
      id: 6,
      name: 'Anguilla',
      flag: {
        mediaType: 1,
        path: 'country/96ac8b60-046c-47e2-82b1-4431ad4742e4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/96ac8b60-046c-47e2-82b1-4431ad4742e4',
      },
    },
    {
      id: 7,
      name: 'Antigua and Barbuda',
      flag: {
        mediaType: 1,
        path: 'country/245756ba-4db1-48b2-84ff-4523c1c037c5',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/245756ba-4db1-48b2-84ff-4523c1c037c5',
      },
    },
    {
      id: 8,
      name: 'Argentina',
      flag: {
        mediaType: 1,
        path: 'country/f163b249-dcce-491b-8df5-3b7bc75d8f66',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/f163b249-dcce-491b-8df5-3b7bc75d8f66',
      },
    },
    {
      id: 9,
      name: 'Armenia',
      flag: {
        mediaType: 1,
        path: 'country/6eb86146-1580-49f3-820a-f5e2d36ef88f',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/6eb86146-1580-49f3-820a-f5e2d36ef88f',
      },
    },
    {
      id: 10,
      name: 'Aruba',
      flag: {
        mediaType: 1,
        path: 'country/9a782313-a5f9-403e-b8d8-fc9658a6c7a8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/9a782313-a5f9-403e-b8d8-fc9658a6c7a8',
      },
    },
    {
      id: 11,
      name: 'Australia',
      flag: {
        mediaType: 1,
        path: 'country/dd9cad88-1473-4b40-9656-5e28d72c8a8f',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/dd9cad88-1473-4b40-9656-5e28d72c8a8f',
      },
    },
    {
      id: 12,
      name: 'Austria',
      flag: {
        mediaType: 1,
        path: 'country/0e4177f8-780e-483b-9747-2e51bff83db8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/0e4177f8-780e-483b-9747-2e51bff83db8',
      },
    },
    {
      id: 13,
      name: 'Azerbaijan',
      flag: {
        mediaType: 1,
        path: 'country/b15af021-fb33-4804-aaa1-a7bd9a2f568e',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/b15af021-fb33-4804-aaa1-a7bd9a2f568e',
      },
    },
    {
      id: 14,
      name: 'Bahamas',
      flag: {
        mediaType: 1,
        path: 'country/02d8ca98-3064-4737-925a-7d12512d6e24',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/02d8ca98-3064-4737-925a-7d12512d6e24',
      },
    },
    {
      id: 15,
      name: 'Bahrain',
      flag: {
        mediaType: 1,
        path: 'country/ee6b0ccb-3847-4f2c-8f87-60ab8a0b65ae',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ee6b0ccb-3847-4f2c-8f87-60ab8a0b65ae',
      },
    },
    {
      id: 16,
      name: 'Bangladesh',
      flag: {
        mediaType: 1,
        path: 'country/442be710-a382-41c3-b7ba-57955a463322',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/442be710-a382-41c3-b7ba-57955a463322',
      },
    },
    {
      id: 17,
      name: 'Barbados',
      flag: {
        mediaType: 1,
        path: 'country/a0b14a78-ee44-4014-9b3b-31adec0186fe',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a0b14a78-ee44-4014-9b3b-31adec0186fe',
      },
    },
    {
      id: 18,
      name: 'Belarus',
      flag: {
        mediaType: 1,
        path: 'country/78da1df9-71ab-4cbb-845c-b85b12ab051e',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/78da1df9-71ab-4cbb-845c-b85b12ab051e',
      },
    },
    {
      id: 19,
      name: 'Belgium',
      flag: {
        mediaType: 1,
        path: 'country/99023b5b-ff95-45ce-a0c1-de4febf29623',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/99023b5b-ff95-45ce-a0c1-de4febf29623',
      },
    },
    {
      id: 20,
      name: 'Belize',
      flag: {
        mediaType: 1,
        path: 'country/c47597ff-21af-4e50-92ec-42dc011fd95e',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/c47597ff-21af-4e50-92ec-42dc011fd95e',
      },
    },
    {
      id: 21,
      name: 'Benin',
      flag: {
        mediaType: 1,
        path: 'country/51121760-383a-4133-a7cc-3de281738bbb',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/51121760-383a-4133-a7cc-3de281738bbb',
      },
    },
    {
      id: 22,
      name: 'Bermuda',
      flag: {
        mediaType: 1,
        path: 'country/dff58923-983c-476c-b330-aa613acf9f7e',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/dff58923-983c-476c-b330-aa613acf9f7e',
      },
    },
    {
      id: 23,
      name: 'Bhutan',
      flag: {
        mediaType: 1,
        path: 'country/075026f3-4bb1-40ff-b934-589ef9b94021',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/075026f3-4bb1-40ff-b934-589ef9b94021',
      },
    },
    {
      id: 24,
      name: 'Bolivia',
      flag: {
        mediaType: 1,
        path: 'country/cc6e5949-689e-490f-9e64-e6eb13b6297d',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/cc6e5949-689e-490f-9e64-e6eb13b6297d',
      },
    },
    {
      id: 25,
      name: 'Bonaire',
      flag: {
        mediaType: 1,
        path: 'country/3e3ecc38-b04e-47cd-95eb-8909931b4730',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/3e3ecc38-b04e-47cd-95eb-8909931b4730',
      },
    },
    {
      id: 223,
      name: 'Bosnia and Herzegovina',
      flag: {
        mediaType: 1,
        path: 'country/0ff54aae-df30-46b9-9d71-0c25eb0f4d3d',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/0ff54aae-df30-46b9-9d71-0c25eb0f4d3d',
      },
    },
    {
      id: 26,
      name: 'Botswana',
      flag: {
        mediaType: 1,
        path: 'country/ffebaadb-afed-42c6-bdd4-188bf679c773',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ffebaadb-afed-42c6-bdd4-188bf679c773',
      },
    },
    {
      id: 27,
      name: 'Brazil',
      flag: {
        mediaType: 1,
        path: 'country/65b2a64d-6164-4e27-be37-ba4d07bddd93',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/65b2a64d-6164-4e27-be37-ba4d07bddd93',
      },
    },
    {
      id: 28,
      name: 'British Virgin Islands',
      flag: {
        mediaType: 1,
        path: 'country/d21c3bcd-027a-4546-b49e-f54be161e228',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d21c3bcd-027a-4546-b49e-f54be161e228',
      },
    },
    {
      id: 29,
      name: 'Brunei Darussalam',
      flag: {
        mediaType: 1,
        path: 'country/a0a162cd-10a7-4f79-93ae-417ea9aeaf13',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a0a162cd-10a7-4f79-93ae-417ea9aeaf13',
      },
    },
    {
      id: 30,
      name: 'Bulgaria',
      flag: {
        mediaType: 1,
        path: 'country/67747efb-58c2-4e94-9eae-d0d75a09a36a',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/67747efb-58c2-4e94-9eae-d0d75a09a36a',
      },
    },
    {
      id: 31,
      name: 'Burkina Faso',
      flag: {
        mediaType: 1,
        path: 'country/126143f5-9e06-4cfb-a96f-6c332d047c31',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/126143f5-9e06-4cfb-a96f-6c332d047c31',
      },
    },
    {
      id: 32,
      name: 'Burundi',
      flag: {
        mediaType: 1,
        path: 'country/4cee32cd-62b9-4723-9062-ac295cf9e2be',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/4cee32cd-62b9-4723-9062-ac295cf9e2be',
      },
    },
    {
      id: 33,
      name: 'Cambodia',
      flag: {
        mediaType: 1,
        path: 'country/d846e11b-965c-4976-88be-a57e2542c075',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d846e11b-965c-4976-88be-a57e2542c075',
      },
    },
    {
      id: 34,
      name: 'Cameroon',
      flag: {
        mediaType: 1,
        path: 'country/dd8cf5ae-23bd-44fb-b1e3-c14d46bb1d4f',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/dd8cf5ae-23bd-44fb-b1e3-c14d46bb1d4f',
      },
    },
    {
      id: 35,
      name: 'Canada',
      flag: {
        mediaType: 1,
        path: 'country/ef055abb-5f82-4dd9-ad31-58455bdfeec8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ef055abb-5f82-4dd9-ad31-58455bdfeec8',
      },
    },
    {
      id: 36,
      name: 'Cape Verde',
      flag: {
        mediaType: 1,
        path: 'country/9397a376-1551-48e9-83fa-795d15cd925a',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/9397a376-1551-48e9-83fa-795d15cd925a',
      },
    },
    {
      id: 37,
      name: 'Cayman Islands',
      flag: {
        mediaType: 1,
        path: 'country/7032b430-f041-4522-86a4-b854f6489230',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/7032b430-f041-4522-86a4-b854f6489230',
      },
    },
    {
      id: 38,
      name: 'Central African Republic',
      flag: {
        mediaType: 1,
        path: 'country/e0094caf-00be-46b6-9ad2-c017a20aa1f4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e0094caf-00be-46b6-9ad2-c017a20aa1f4',
      },
    },
    {
      id: 39,
      name: 'Chad',
      flag: {
        mediaType: 1,
        path: 'country/afb0447f-6c8a-4994-ae7f-c424ba490ff7',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/afb0447f-6c8a-4994-ae7f-c424ba490ff7',
      },
    },
    {
      id: 40,
      name: 'Chile',
      flag: {
        mediaType: 1,
        path: 'country/78d961d2-4a85-415e-b62a-58d2ba89b29c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/78d961d2-4a85-415e-b62a-58d2ba89b29c',
      },
    },
    {
      id: 41,
      name: 'China PR',
      flag: {
        mediaType: 1,
        path: 'country/994ff154-aeac-4c78-903a-0cdf9ddbc98d',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/994ff154-aeac-4c78-903a-0cdf9ddbc98d',
      },
    },
    {
      id: 42,
      name: 'Chinese Taipei',
      flag: {
        mediaType: 1,
        path: 'country/be293286-5bb6-4716-a859-3572450065bf',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/be293286-5bb6-4716-a859-3572450065bf',
      },
    },
    {
      id: 43,
      name: 'Colombia',
      flag: {
        mediaType: 1,
        path: 'country/fce10d07-a776-4a4e-acce-1a93a8e63c9a',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/fce10d07-a776-4a4e-acce-1a93a8e63c9a',
      },
    },
    {
      id: 44,
      name: 'Comoros',
      flag: {
        mediaType: 1,
        path: 'country/2d0f6709-9634-43d3-b8eb-3ada90e4722c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/2d0f6709-9634-43d3-b8eb-3ada90e4722c',
      },
    },
    {
      id: 45,
      name: 'Congo',
      flag: {
        mediaType: 1,
        path: 'country/2eb52980-2819-4a4b-804e-1d4fdcf2fc70',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/2eb52980-2819-4a4b-804e-1d4fdcf2fc70',
      },
    },
    {
      id: 46,
      name: 'Congo DR',
      flag: {
        mediaType: 1,
        path: 'country/a9a3b007-8abd-430c-b8b5-bd38d286fd56',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a9a3b007-8abd-430c-b8b5-bd38d286fd56',
      },
    },
    {
      id: 47,
      name: 'Cook Islands',
      flag: {
        mediaType: 1,
        path: 'country/b62cc678-66c9-4fd8-92c9-5849f07f1d98',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/b62cc678-66c9-4fd8-92c9-5849f07f1d98',
      },
    },
    {
      id: 48,
      name: 'Costa Rica',
      flag: {
        mediaType: 1,
        path: 'country/9aa3be6c-d4d0-4f3a-b6c1-7cb95a1c44c8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/9aa3be6c-d4d0-4f3a-b6c1-7cb95a1c44c8',
      },
    },
    {
      id: 49,
      name: 'Croatia',
      flag: {
        mediaType: 1,
        path: 'country/d172c6b3-7ae0-49c6-a858-5700ba3880f0',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d172c6b3-7ae0-49c6-a858-5700ba3880f0',
      },
    },
    {
      id: 50,
      name: 'Cuba',
      flag: {
        mediaType: 1,
        path: 'country/b2073772-6646-466a-995c-0bcbaa3b887c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/b2073772-6646-466a-995c-0bcbaa3b887c',
      },
    },
    {
      id: 51,
      name: 'Curaçao',
      flag: {
        mediaType: 1,
        path: 'country/2cab2d93-4d33-44d8-a98c-f745e5619369',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/2cab2d93-4d33-44d8-a98c-f745e5619369',
      },
    },
    {
      id: 52,
      name: 'Cyprus',
      flag: {
        mediaType: 1,
        path: 'country/577f5d25-f4bf-43ba-ae5e-c2379e15497c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/577f5d25-f4bf-43ba-ae5e-c2379e15497c',
      },
    },
    {
      id: 53,
      name: 'Czech Republic',
      flag: {
        mediaType: 1,
        path: 'country/14144c0a-9540-4a30-b3c0-4f9817317b9b',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/14144c0a-9540-4a30-b3c0-4f9817317b9b',
      },
    },
    {
      id: 54,
      name: 'Denmark',
      flag: {
        mediaType: 1,
        path: 'country/7508f7de-6a53-4252-a064-4b1cdf44d39b',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/7508f7de-6a53-4252-a064-4b1cdf44d39b',
      },
    },
    {
      id: 55,
      name: 'Djibouti',
      flag: {
        mediaType: 1,
        path: 'country/abc4fedc-2a9b-4644-aa4f-66148eeb688d',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/abc4fedc-2a9b-4644-aa4f-66148eeb688d',
      },
    },
    {
      id: 56,
      name: 'Dominica',
      flag: {
        mediaType: 1,
        path: 'country/dd379900-9089-4f03-a4de-acc2627f07dd',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/dd379900-9089-4f03-a4de-acc2627f07dd',
      },
    },
    {
      id: 57,
      name: 'Dominican Republic',
      flag: {
        mediaType: 1,
        path: 'country/265084eb-95fd-4a9e-a0fe-fd203871e0ce',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/265084eb-95fd-4a9e-a0fe-fd203871e0ce',
      },
    },
    {
      id: 58,
      name: 'Ecuador',
      flag: {
        mediaType: 1,
        path: 'country/2d114a99-572a-4e56-a6e1-0d5539bee9c8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/2d114a99-572a-4e56-a6e1-0d5539bee9c8',
      },
    },
    {
      id: 59,
      name: 'Egypt',
      flag: {
        mediaType: 1,
        path: 'country/5e89ed4a-abf1-4561-b880-4c75b12db106',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/5e89ed4a-abf1-4561-b880-4c75b12db106',
      },
    },
    {
      id: 60,
      name: 'El Salvador',
      flag: {
        mediaType: 1,
        path: 'country/35fe7347-1e30-472c-914a-fb505cf71ce0',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/35fe7347-1e30-472c-914a-fb505cf71ce0',
      },
    },
    {
      id: 61,
      name: 'Equatorial Guinea',
      flag: {
        mediaType: 1,
        path: 'country/4312e1e4-75cb-4fd9-bbdd-f9a473f377a6',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/4312e1e4-75cb-4fd9-bbdd-f9a473f377a6',
      },
    },
    {
      id: 62,
      name: 'Eritrea',
      flag: {
        mediaType: 1,
        path: 'country/e6ae93da-b550-47f8-ad88-af04e80ebaf6',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e6ae93da-b550-47f8-ad88-af04e80ebaf6',
      },
    },
    {
      id: 63,
      name: 'Estonia',
      flag: {
        mediaType: 1,
        path: 'country/663476cc-064a-4378-9b18-27f0fa984260',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/663476cc-064a-4378-9b18-27f0fa984260',
      },
    },
    {
      id: 64,
      name: 'Ethiopia',
      flag: {
        mediaType: 1,
        path: 'country/a1082109-edd9-4b95-9eb5-4e3c5ad6e352',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a1082109-edd9-4b95-9eb5-4e3c5ad6e352',
      },
    },
    {
      id: 65,
      name: 'Faroe Islands',
      flag: {
        mediaType: 1,
        path: 'country/2ed3d864-3bfe-4fed-b1bc-4d84f05a8c85',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/2ed3d864-3bfe-4fed-b1bc-4d84f05a8c85',
      },
    },
    {
      id: 66,
      name: 'Fiji',
      flag: {
        mediaType: 1,
        path: 'country/23673ce9-a09d-4e5f-9176-79938d971c61',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/23673ce9-a09d-4e5f-9176-79938d971c61',
      },
    },
    {
      id: 67,
      name: 'Finland',
      flag: {
        mediaType: 1,
        path: 'country/766fc271-5cd7-424d-940a-8d9d899a2445',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/766fc271-5cd7-424d-940a-8d9d899a2445',
      },
    },
    {
      id: 68,
      name: 'France',
      flag: {
        mediaType: 1,
        path: 'country/2a68c8f5-c68a-4bc6-a9a4-da92081dca25',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/2a68c8f5-c68a-4bc6-a9a4-da92081dca25',
      },
    },
    {
      id: 69,
      name: 'French Guiana',
      flag: {
        mediaType: 1,
        path: 'country/fe7fee91-135e-4366-9a6b-e2b74a6a2dd8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/fe7fee91-135e-4366-9a6b-e2b74a6a2dd8',
      },
    },
    {
      id: 70,
      name: 'FYR Macedonia',
      flag: {
        mediaType: 1,
        path: 'country/d10d4ea4-26d0-496a-a617-6a6167fea959',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d10d4ea4-26d0-496a-a617-6a6167fea959',
      },
    },
    {
      id: 71,
      name: 'Gabon',
      flag: {
        mediaType: 1,
        path: 'country/16d00987-f422-47e5-a13b-af3a74f519e2',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/16d00987-f422-47e5-a13b-af3a74f519e2',
      },
    },
    {
      id: 72,
      name: 'Gambia',
      flag: {
        mediaType: 1,
        path: 'country/08f9e9ad-c48e-4241-8768-cb5d7942976c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/08f9e9ad-c48e-4241-8768-cb5d7942976c',
      },
    },
    {
      id: 73,
      name: 'Georgia',
      flag: {
        mediaType: 1,
        path: 'country/bf5a3216-6b7b-4ceb-83a8-11f4a5687127',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/bf5a3216-6b7b-4ceb-83a8-11f4a5687127',
      },
    },
    {
      id: 74,
      name: 'Germany',
      flag: {
        mediaType: 1,
        path: 'country/b4e9df46-0618-4ffe-9cc6-f21faca3f178',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/b4e9df46-0618-4ffe-9cc6-f21faca3f178',
      },
    },
    {
      id: 75,
      name: 'Ghana',
      flag: {
        mediaType: 1,
        path: 'country/e354ba18-23d1-4167-b9e9-39173b94883c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e354ba18-23d1-4167-b9e9-39173b94883c',
      },
    },
    {
      id: 76,
      name: 'Gibraltar',
      flag: {
        mediaType: 1,
        path: 'country/a2c52e05-d4a8-46ee-a439-17d29e9f600b',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a2c52e05-d4a8-46ee-a439-17d29e9f600b',
      },
    },
    {
      id: 77,
      name: 'Greece',
      flag: {
        mediaType: 1,
        path: 'country/72027532-e62b-44c4-8329-376c58724e17',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/72027532-e62b-44c4-8329-376c58724e17',
      },
    },
    {
      id: 78,
      name: 'Grenada',
      flag: {
        mediaType: 1,
        path: 'country/da3dfae6-c259-4e84-9576-e08ed163f197',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/da3dfae6-c259-4e84-9576-e08ed163f197',
      },
    },
    {
      id: 79,
      name: 'Guadeloupe',
      flag: {
        mediaType: 1,
        path: 'country/558e6057-f5cf-46e1-a0b4-5806711984a3',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/558e6057-f5cf-46e1-a0b4-5806711984a3',
      },
    },
    {
      id: 80,
      name: 'Guam',
      flag: {
        mediaType: 1,
        path: 'country/f4061520-2658-4a66-9845-6decd2210a5d',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/f4061520-2658-4a66-9845-6decd2210a5d',
      },
    },
    {
      id: 81,
      name: 'Guatemala',
      flag: {
        mediaType: 1,
        path: 'country/1309f0b3-966a-4d79-a968-bc039371eb34',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/1309f0b3-966a-4d79-a968-bc039371eb34',
      },
    },
    {
      id: 82,
      name: 'Guinea',
      flag: {
        mediaType: 1,
        path: 'country/ca62b8e4-4eba-4749-af03-c03a84f7300b',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ca62b8e4-4eba-4749-af03-c03a84f7300b',
      },
    },
    {
      id: 83,
      name: 'Guinea-Bissau',
      flag: {
        mediaType: 1,
        path: 'country/07328c35-8f34-4942-aaab-108c7378b8a7',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/07328c35-8f34-4942-aaab-108c7378b8a7',
      },
    },
    {
      id: 84,
      name: 'Guyana',
      flag: {
        mediaType: 1,
        path: 'country/c28bef51-54bd-42da-8c87-3ebdb449f280',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/c28bef51-54bd-42da-8c87-3ebdb449f280',
      },
    },
    {
      id: 85,
      name: 'Haiti',
      flag: {
        mediaType: 1,
        path: 'country/64631bde-fde7-4a0a-a7e1-db62dba4c457',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/64631bde-fde7-4a0a-a7e1-db62dba4c457',
      },
    },
    {
      id: 86,
      name: 'Honduras',
      flag: {
        mediaType: 1,
        path: 'country/e75d04f7-3aa5-4d36-854c-61840f0eb057',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e75d04f7-3aa5-4d36-854c-61840f0eb057',
      },
    },
    {
      id: 87,
      name: 'Hong Kong',
      flag: {
        mediaType: 1,
        path: 'country/9ee24c2d-b006-4f8c-8ad4-437c46e69cda',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/9ee24c2d-b006-4f8c-8ad4-437c46e69cda',
      },
    },
    {
      id: 88,
      name: 'Hungary',
      flag: {
        mediaType: 1,
        path: 'country/511a7a88-d695-442d-a232-2094909cd443',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/511a7a88-d695-442d-a232-2094909cd443',
      },
    },
    {
      id: 89,
      name: 'Iceland',
      flag: {
        mediaType: 1,
        path: 'country/f70c41fe-0613-43cd-ab79-930051e1b82a',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/f70c41fe-0613-43cd-ab79-930051e1b82a',
      },
    },
    {
      id: 90,
      name: 'India',
      flag: {
        mediaType: 1,
        path: 'country/ef53aff6-fb59-4da1-9899-b1438a5ce959',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ef53aff6-fb59-4da1-9899-b1438a5ce959',
      },
    },
    {
      id: 91,
      name: 'Indonesia',
      flag: {
        mediaType: 1,
        path: 'country/39246cd5-5140-4d00-b988-5086e411d161',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/39246cd5-5140-4d00-b988-5086e411d161',
      },
    },
    {
      id: 92,
      name: 'Iran',
      flag: {
        mediaType: 1,
        path: 'country/5d3c951d-5b1a-424c-af8e-545cefbc9011',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/5d3c951d-5b1a-424c-af8e-545cefbc9011',
      },
    },
    {
      id: 93,
      name: 'Iraq',
      flag: {
        mediaType: 1,
        path: 'country/587f8d37-5fc3-43d3-9bcf-8c00d3c1e28c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/587f8d37-5fc3-43d3-9bcf-8c00d3c1e28c',
      },
    },
    {
      id: 94,
      name: 'Israel',
      flag: {
        mediaType: 1,
        path: 'country/350b17da-7875-4e15-9085-a737de8ed7b4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/350b17da-7875-4e15-9085-a737de8ed7b4',
      },
    },
    {
      id: 95,
      name: 'Italy',
      flag: {
        mediaType: 1,
        path: 'country/e4c2a300-aaba-4d31-9342-7be22a10090e',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e4c2a300-aaba-4d31-9342-7be22a10090e',
      },
    },
    {
      id: 96,
      name: 'Ivory Coast',
      flag: {
        mediaType: 1,
        path: 'country/a9f868eb-0f6c-4d2c-a9b1-2aff5518ca12',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a9f868eb-0f6c-4d2c-a9b1-2aff5518ca12',
      },
    },
    {
      id: 97,
      name: 'Jamaica',
      flag: {
        mediaType: 1,
        path: 'country/2181c04a-22dd-429d-895f-d4e8e299050c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/2181c04a-22dd-429d-895f-d4e8e299050c',
      },
    },
    {
      id: 98,
      name: 'Japan',
      flag: {
        mediaType: 1,
        path: 'country/4761a30b-2672-4b54-9b42-11073d2d552f',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/4761a30b-2672-4b54-9b42-11073d2d552f',
      },
    },
    {
      id: 99,
      name: 'Jordan',
      flag: {
        mediaType: 1,
        path: 'country/d8f60ba5-58f7-47a7-a623-9200f517d0b6',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d8f60ba5-58f7-47a7-a623-9200f517d0b6',
      },
    },
    {
      id: 100,
      name: 'Kazakhstan',
      flag: {
        mediaType: 1,
        path: 'country/51566afb-38c9-45ea-99eb-f80313dc488d',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/51566afb-38c9-45ea-99eb-f80313dc488d',
      },
    },
    {
      id: 101,
      name: 'Kenya',
      flag: {
        mediaType: 1,
        path: 'country/aba0b016-b9d6-4d65-8501-c17bfa5d5218',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/aba0b016-b9d6-4d65-8501-c17bfa5d5218',
      },
    },
    {
      id: 102,
      name: 'Kiribati',
      flag: {
        mediaType: 1,
        path: 'country/6ee67e50-3ecb-4cca-bbda-db4bab6926eb',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/6ee67e50-3ecb-4cca-bbda-db4bab6926eb',
      },
    },
    {
      id: 103,
      name: 'Kosovo',
      flag: {
        mediaType: 1,
        path: 'country/de5b073e-c189-4a2a-9134-d7964d1d2a6f',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/de5b073e-c189-4a2a-9134-d7964d1d2a6f',
      },
    },
    {
      id: 104,
      name: 'Kuwait',
      flag: {
        mediaType: 1,
        path: 'country/bc4573b0-7842-4af9-a5ab-f9b68805cc7a',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/bc4573b0-7842-4af9-a5ab-f9b68805cc7a',
      },
    },
    {
      id: 105,
      name: 'Kyrgyzstan',
      flag: {
        mediaType: 1,
        path: 'country/d05631e7-b798-4114-88fb-88114edb1306',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d05631e7-b798-4114-88fb-88114edb1306',
      },
    },
    {
      id: 106,
      name: 'Laos',
      flag: {
        mediaType: 1,
        path: 'country/f4ea96ce-06a2-457b-a739-eb393313cc5b',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/f4ea96ce-06a2-457b-a739-eb393313cc5b',
      },
    },
    {
      id: 107,
      name: 'Latvia',
      flag: {
        mediaType: 1,
        path: 'country/78723149-0456-4628-8d59-a31bc5cfe107',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/78723149-0456-4628-8d59-a31bc5cfe107',
      },
    },
    {
      id: 108,
      name: 'Lebanon',
      flag: {
        mediaType: 1,
        path: 'country/e0de1c9a-4913-4a55-abe7-252c28152e7c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e0de1c9a-4913-4a55-abe7-252c28152e7c',
      },
    },
    {
      id: 109,
      name: 'Lesotho',
      flag: {
        mediaType: 1,
        path: 'country/0ebf62f2-5fc4-4914-869d-c632bdcd3ea1',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/0ebf62f2-5fc4-4914-869d-c632bdcd3ea1',
      },
    },
    {
      id: 110,
      name: 'Liberia',
      flag: {
        mediaType: 1,
        path: 'country/1a50f4d9-c50e-4056-a2bf-1a53aad2ce56',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/1a50f4d9-c50e-4056-a2bf-1a53aad2ce56',
      },
    },
    {
      id: 111,
      name: 'Libya',
      flag: {
        mediaType: 1,
        path: 'country/de28525c-54bf-471d-aefe-debb17211d97',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/de28525c-54bf-471d-aefe-debb17211d97',
      },
    },
    {
      id: 112,
      name: 'Liechtenstein',
      flag: {
        mediaType: 1,
        path: 'country/f259c500-08ef-4d16-9d83-cf2ea34d859a',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/f259c500-08ef-4d16-9d83-cf2ea34d859a',
      },
    },
    {
      id: 113,
      name: 'Lithuania',
      flag: {
        mediaType: 1,
        path: 'country/2df9e705-1eaa-4f33-91e6-896761298194',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/2df9e705-1eaa-4f33-91e6-896761298194',
      },
    },
    {
      id: 114,
      name: 'Luxembourg',
      flag: {
        mediaType: 1,
        path: 'country/446ec914-f1f7-4478-af07-e5e0636f8a65',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/446ec914-f1f7-4478-af07-e5e0636f8a65',
      },
    },
    {
      id: 115,
      name: 'Macau',
      flag: {
        mediaType: 1,
        path: 'country/bd9a457c-5b2c-49f1-998d-5c4a57198037',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/bd9a457c-5b2c-49f1-998d-5c4a57198037',
      },
    },
    {
      id: 116,
      name: 'Madagascar',
      flag: {
        mediaType: 1,
        path: 'country/3818f854-387b-432b-bd38-15c10f7f4088',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/3818f854-387b-432b-bd38-15c10f7f4088',
      },
    },
    {
      id: 117,
      name: 'Malawi',
      flag: {
        mediaType: 1,
        path: 'country/ee959f6d-bc08-4313-86d8-e0271e74d93d',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ee959f6d-bc08-4313-86d8-e0271e74d93d',
      },
    },
    {
      id: 118,
      name: 'Malaysia',
      flag: {
        mediaType: 1,
        path: 'country/a5cf3f63-7dae-47e2-974d-69d24c001901',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a5cf3f63-7dae-47e2-974d-69d24c001901',
      },
    },
    {
      id: 119,
      name: 'Maldives',
      flag: {
        mediaType: 1,
        path: 'country/4b00d156-e1e2-4d9d-9428-767dc57b7bb3',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/4b00d156-e1e2-4d9d-9428-767dc57b7bb3',
      },
    },
    {
      id: 120,
      name: 'Mali',
      flag: {
        mediaType: 1,
        path: 'country/4eb17dd8-d529-4ce5-aa47-7c8398ffb5c4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/4eb17dd8-d529-4ce5-aa47-7c8398ffb5c4',
      },
    },
    {
      id: 121,
      name: 'Malta',
      flag: {
        mediaType: 1,
        path: 'country/b779e116-2710-4c03-b079-f30b03883104',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/b779e116-2710-4c03-b079-f30b03883104',
      },
    },
    {
      id: 122,
      name: 'Martinique',
      flag: {
        mediaType: 1,
        path: 'country/e78320eb-1bcc-4629-8123-88be877cdfbf',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e78320eb-1bcc-4629-8123-88be877cdfbf',
      },
    },
    {
      id: 123,
      name: 'Mauritania',
      flag: {
        mediaType: 1,
        path: 'country/1babae1f-75b1-4d36-9576-f4241623a6bf',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/1babae1f-75b1-4d36-9576-f4241623a6bf',
      },
    },
    {
      id: 124,
      name: 'Mauritius',
      flag: {
        mediaType: 1,
        path: 'country/258026ae-b95a-4042-ad3c-509d6c830b5b',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/258026ae-b95a-4042-ad3c-509d6c830b5b',
      },
    },
    {
      id: 125,
      name: 'Mexico',
      flag: {
        mediaType: 1,
        path: 'country/e63d768f-e4c0-41dc-b76b-592da5f4ad25',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e63d768f-e4c0-41dc-b76b-592da5f4ad25',
      },
    },
    {
      id: 126,
      name: 'Moldova',
      flag: {
        mediaType: 1,
        path: 'country/8fa0c283-0d18-48a3-9e31-33fbaaf16531',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/8fa0c283-0d18-48a3-9e31-33fbaaf16531',
      },
    },
    {
      id: 127,
      name: 'Mongolia',
      flag: {
        mediaType: 1,
        path: 'country/bf6176f3-878e-4871-ae78-d88e88158ea8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/bf6176f3-878e-4871-ae78-d88e88158ea8',
      },
    },
    {
      id: 128,
      name: 'Montenegro',
      flag: {
        mediaType: 1,
        path: 'country/80feb82e-cd45-463a-8712-3f85676ed6f4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/80feb82e-cd45-463a-8712-3f85676ed6f4',
      },
    },
    {
      id: 129,
      name: 'Montserrat',
      flag: {
        mediaType: 1,
        path: 'country/2ee1a9fa-9fab-48dd-abe5-bc00a5f81b74',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/2ee1a9fa-9fab-48dd-abe5-bc00a5f81b74',
      },
    },
    {
      id: 130,
      name: 'Morocco',
      flag: {
        mediaType: 1,
        path: 'country/a86d442f-e481-44d2-9c2a-8738a46b4192',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a86d442f-e481-44d2-9c2a-8738a46b4192',
      },
    },
    {
      id: 131,
      name: 'Mozambique',
      flag: {
        mediaType: 1,
        path: 'country/fcd9b793-55af-4512-ad3a-8b2583297852',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/fcd9b793-55af-4512-ad3a-8b2583297852',
      },
    },
    {
      id: 132,
      name: 'Myanmar',
      flag: {
        mediaType: 1,
        path: 'country/0edbd35b-8883-4241-9942-1b200691d87c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/0edbd35b-8883-4241-9942-1b200691d87c',
      },
    },
    {
      id: 133,
      name: 'Namibia',
      flag: {
        mediaType: 1,
        path: 'country/4c8d434d-ae96-498a-b174-b3200c691a65',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/4c8d434d-ae96-498a-b174-b3200c691a65',
      },
    },
    {
      id: 224,
      name: 'Nepal',
      flag: {
        mediaType: 1,
        path: 'country/d3eff1f0-6ad9-4b1c-80d5-5ffea0b85ba7',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d3eff1f0-6ad9-4b1c-80d5-5ffea0b85ba7',
      },
    },
    {
      id: 134,
      name: 'Netherlands',
      flag: {
        mediaType: 1,
        path: 'country/3c37435d-ace8-4bb5-a58d-b4653bf2e154',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/3c37435d-ace8-4bb5-a58d-b4653bf2e154',
      },
    },
    {
      id: 135,
      name: 'New Caledonia',
      flag: {
        mediaType: 1,
        path: 'country/01347bd3-a5fb-492d-a8ee-0ec60f866264',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/01347bd3-a5fb-492d-a8ee-0ec60f866264',
      },
    },
    {
      id: 136,
      name: 'New Zealand',
      flag: {
        mediaType: 1,
        path: 'country/ba9cbb1b-e892-4014-8e98-acea3e329592',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ba9cbb1b-e892-4014-8e98-acea3e329592',
      },
    },
    {
      id: 137,
      name: 'Nicaragua',
      flag: {
        mediaType: 1,
        path: 'country/f06da41a-c0c9-41db-af0f-5a22ac16e105',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/f06da41a-c0c9-41db-af0f-5a22ac16e105',
      },
    },
    {
      id: 138,
      name: 'Niger',
      flag: {
        mediaType: 1,
        path: 'country/ab8f06e4-b4e9-4d0e-82f8-ce74a6d07ea8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ab8f06e4-b4e9-4d0e-82f8-ce74a6d07ea8',
      },
    },
    {
      id: 139,
      name: 'Nigeria',
      flag: {
        mediaType: 1,
        path: 'country/7d12f8a1-855f-4ccc-aa94-b790ef39e2e4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/7d12f8a1-855f-4ccc-aa94-b790ef39e2e4',
      },
    },
    {
      id: 140,
      name: 'Niue',
      flag: {
        mediaType: 1,
        path: 'country/31512c59-bd01-45ce-ab4a-cee716e053c3',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/31512c59-bd01-45ce-ab4a-cee716e053c3',
      },
    },
    {
      id: 141,
      name: 'North Korea',
      flag: {
        mediaType: 1,
        path: 'country/06cbac9a-6546-4a39-9252-020b8bb5ad48',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/06cbac9a-6546-4a39-9252-020b8bb5ad48',
      },
    },
    {
      id: 143,
      name: 'Northern Mariana Islands',
      flag: {
        mediaType: 1,
        path: 'country/cefe371b-d705-4587-bf66-13df1488e0e4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/cefe371b-d705-4587-bf66-13df1488e0e4',
      },
    },
    {
      id: 144,
      name: 'Norway',
      flag: {
        mediaType: 1,
        path: 'country/a08b3a12-b0f9-4d9e-9546-8f4c3b769a81',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a08b3a12-b0f9-4d9e-9546-8f4c3b769a81',
      },
    },
    {
      id: 145,
      name: 'Oman',
      flag: {
        mediaType: 1,
        path: 'country/5dab3960-b19f-4160-9357-2be7d4f42c6c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/5dab3960-b19f-4160-9357-2be7d4f42c6c',
      },
    },
    {
      id: 146,
      name: 'Pakistan',
      flag: {
        mediaType: 1,
        path: 'country/ab39cb0e-c87f-4ce5-b9b1-f88198bd19cd',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ab39cb0e-c87f-4ce5-b9b1-f88198bd19cd',
      },
    },
    {
      id: 147,
      name: 'Palestine',
      flag: {
        mediaType: 1,
        path: 'country/b15da1ff-dc60-4e25-969b-9a043e3677c0',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/b15da1ff-dc60-4e25-969b-9a043e3677c0',
      },
    },
    {
      id: 148,
      name: 'Panama',
      flag: {
        mediaType: 1,
        path: 'country/7b31bde9-9351-4b82-bb6c-8a28542c2e30',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/7b31bde9-9351-4b82-bb6c-8a28542c2e30',
      },
    },
    {
      id: 149,
      name: 'Papua New Guinea',
      flag: {
        mediaType: 1,
        path: 'country/fbdb5a24-ff90-4ff9-8b73-892972dd3d02',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/fbdb5a24-ff90-4ff9-8b73-892972dd3d02',
      },
    },
    {
      id: 150,
      name: 'Paraguay',
      flag: {
        mediaType: 1,
        path: 'country/b9ad42a6-4c4b-44a6-86cd-dd2581cfa380',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/b9ad42a6-4c4b-44a6-86cd-dd2581cfa380',
      },
    },
    {
      id: 151,
      name: 'Peru',
      flag: {
        mediaType: 1,
        path: 'country/d3259568-2bd8-4fe3-a3dc-f6a49ff38a2a',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d3259568-2bd8-4fe3-a3dc-f6a49ff38a2a',
      },
    },
    {
      id: 152,
      name: 'Philippines',
      flag: {
        mediaType: 1,
        path: 'country/649881ad-ef4e-4b7c-9c7e-0a4601a696e2',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/649881ad-ef4e-4b7c-9c7e-0a4601a696e2',
      },
    },
    {
      id: 153,
      name: 'Poland',
      flag: {
        mediaType: 1,
        path: 'country/00e51154-5dc7-4d27-a6dd-bcf4ba8268ff',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/00e51154-5dc7-4d27-a6dd-bcf4ba8268ff',
      },
    },
    {
      id: 154,
      name: 'Portugal',
      flag: {
        mediaType: 1,
        path: 'country/fed11d32-d706-46f3-bad1-86ae5d0e48f6',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/fed11d32-d706-46f3-bad1-86ae5d0e48f6',
      },
    },
    {
      id: 155,
      name: 'Puerto Rico',
      flag: {
        mediaType: 1,
        path: 'country/94d84593-31f8-4a9b-b72b-786923a541cd',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/94d84593-31f8-4a9b-b72b-786923a541cd',
      },
    },
    {
      id: 156,
      name: 'Qatar',
      flag: {
        mediaType: 1,
        path: 'country/8ac57078-6142-4a1c-a3b0-af03f366ff68',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/8ac57078-6142-4a1c-a3b0-af03f366ff68',
      },
    },
    {
      id: 157,
      name: 'Réunion',
      flag: {
        mediaType: 1,
        path: 'country/228362a8-4bfb-4b86-9d0e-abeb3ca0c343',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/228362a8-4bfb-4b86-9d0e-abeb3ca0c343',
      },
    },
    {
      id: 158,
      name: 'Romania',
      flag: {
        mediaType: 1,
        path: 'country/8e235b53-269f-4ba0-9b59-5448a77c517c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/8e235b53-269f-4ba0-9b59-5448a77c517c',
      },
    },
    {
      id: 159,
      name: 'Russia',
      flag: {
        mediaType: 1,
        path: 'country/998a03cc-59d8-42c9-861b-7e65ccea8c16',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/998a03cc-59d8-42c9-861b-7e65ccea8c16',
      },
    },
    {
      id: 160,
      name: 'Rwanda',
      flag: {
        mediaType: 1,
        path: 'country/874b25e9-be5b-4aae-b2dc-83fb25eff088',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/874b25e9-be5b-4aae-b2dc-83fb25eff088',
      },
    },
    {
      id: 161,
      name: 'Saint Kitts and Nevis',
      flag: {
        mediaType: 1,
        path: 'country/14ba83a5-cdfa-4c01-913b-ace63ce59e4c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/14ba83a5-cdfa-4c01-913b-ace63ce59e4c',
      },
    },
    {
      id: 162,
      name: 'Saint Lucia',
      flag: {
        mediaType: 1,
        path: 'country/9c9f85af-fd91-403c-8923-0222d678e41a',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/9c9f85af-fd91-403c-8923-0222d678e41a',
      },
    },
    {
      id: 163,
      name: 'Saint Martin',
      flag: {
        mediaType: 1,
        path: 'country/6e43ce06-e559-465d-a492-26409ff2c0cb',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/6e43ce06-e559-465d-a492-26409ff2c0cb',
      },
    },
    {
      id: 164,
      name: 'Saint Vincent and the Grenadines',
      flag: {
        mediaType: 1,
        path: 'country/458032d5-b8a0-4858-be59-165068d10ec4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/458032d5-b8a0-4858-be59-165068d10ec4',
      },
    },
    {
      id: 165,
      name: 'Samoa',
      flag: {
        mediaType: 1,
        path: 'country/b6c50f9f-1478-47bf-b60b-134225980a1b',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/b6c50f9f-1478-47bf-b60b-134225980a1b',
      },
    },
    {
      id: 166,
      name: 'San Marino',
      flag: {
        mediaType: 1,
        path: 'country/93bed9ee-8671-41f2-a6e4-122c2945e69c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/93bed9ee-8671-41f2-a6e4-122c2945e69c',
      },
    },
    {
      id: 167,
      name: 'São Tomé and Príncipe',
      flag: {
        mediaType: 1,
        path: 'country/8020a29f-1911-4bdf-8879-54a3687cfe04',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/8020a29f-1911-4bdf-8879-54a3687cfe04',
      },
    },
    {
      id: 168,
      name: 'Saudi Arabia',
      flag: {
        mediaType: 1,
        path: 'country/acbcce60-349f-4877-976e-6eb9ebb8e397',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/acbcce60-349f-4877-976e-6eb9ebb8e397',
      },
    },
    {
      id: 170,
      name: 'Senegal',
      flag: {
        mediaType: 1,
        path: 'country/047c7e0d-4018-4483-bf43-1b40e0da80d7',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/047c7e0d-4018-4483-bf43-1b40e0da80d7',
      },
    },
    {
      id: 171,
      name: 'Serbia',
      flag: {
        mediaType: 1,
        path: 'country/3c1d18b3-fef0-4611-affb-4110650c6555',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/3c1d18b3-fef0-4611-affb-4110650c6555',
      },
    },
    {
      id: 172,
      name: 'Seychelles',
      flag: {
        mediaType: 1,
        path: 'country/db374e5c-bbbe-4b05-9211-316a40b9ccc3',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/db374e5c-bbbe-4b05-9211-316a40b9ccc3',
      },
    },
    {
      id: 173,
      name: 'Sierra Leone',
      flag: {
        mediaType: 1,
        path: 'country/338fc1e9-4d29-4e39-b3b5-ce47db638f47',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/338fc1e9-4d29-4e39-b3b5-ce47db638f47',
      },
    },
    {
      id: 174,
      name: 'Singapore',
      flag: {
        mediaType: 1,
        path: 'country/1ecd26b1-79d9-4318-a1b0-90e6e2ba17bc',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/1ecd26b1-79d9-4318-a1b0-90e6e2ba17bc',
      },
    },
    {
      id: 175,
      name: 'Sint Maarten',
      flag: {
        mediaType: 1,
        path: 'country/7b1fc07a-bdce-4992-b9e7-beefed28222e',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/7b1fc07a-bdce-4992-b9e7-beefed28222e',
      },
    },
    {
      id: 176,
      name: 'Slovakia',
      flag: {
        mediaType: 1,
        path: 'country/193ccc54-6c6d-4d18-9966-e19787af960c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/193ccc54-6c6d-4d18-9966-e19787af960c',
      },
    },
    {
      id: 177,
      name: 'Slovenia',
      flag: {
        mediaType: 1,
        path: 'country/84a551d8-d1dd-460a-986c-b82a4f2f9684',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/84a551d8-d1dd-460a-986c-b82a4f2f9684',
      },
    },
    {
      id: 178,
      name: 'Solomon Islands',
      flag: {
        mediaType: 1,
        path: 'country/8f73ed35-27e9-4044-817c-ecde7f3617d4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/8f73ed35-27e9-4044-817c-ecde7f3617d4',
      },
    },
    {
      id: 179,
      name: 'Somalia',
      flag: {
        mediaType: 1,
        path: 'country/be8ae9d0-a22c-49b1-a360-5027f1b87adc',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/be8ae9d0-a22c-49b1-a360-5027f1b87adc',
      },
    },
    {
      id: 180,
      name: 'South Africa',
      flag: {
        mediaType: 1,
        path: 'country/65cf25f6-c8ba-498b-8e02-7f9d5e0caa76',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/65cf25f6-c8ba-498b-8e02-7f9d5e0caa76',
      },
    },
    {
      id: 181,
      name: 'South Korea',
      flag: {
        mediaType: 1,
        path: 'country/e7b1136a-ce53-46f7-8b43-ab9ba22405ee',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e7b1136a-ce53-46f7-8b43-ab9ba22405ee',
      },
    },
    {
      id: 182,
      name: 'South Sudan',
      flag: {
        mediaType: 1,
        path: 'country/f902152b-18b2-487a-9d9a-fc9df3f7b0a8',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/f902152b-18b2-487a-9d9a-fc9df3f7b0a8',
      },
    },
    {
      id: 183,
      name: 'Spain',
      flag: {
        mediaType: 1,
        path: 'country/29630f70-c53b-4484-9076-6c348195d505',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/29630f70-c53b-4484-9076-6c348195d505',
      },
    },
    {
      id: 184,
      name: 'Sri Lanka',
      flag: {
        mediaType: 1,
        path: 'country/eafe618e-f4f9-4fe6-93a4-1dadf6160a79',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/eafe618e-f4f9-4fe6-93a4-1dadf6160a79',
      },
    },
    {
      id: 185,
      name: 'Sudan',
      flag: {
        mediaType: 1,
        path: 'country/e2031d07-cca3-40dc-a290-66098deb8c60',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/e2031d07-cca3-40dc-a290-66098deb8c60',
      },
    },
    {
      id: 186,
      name: 'Suriname',
      flag: {
        mediaType: 1,
        path: 'country/86f98a3c-6097-4ab5-baa5-56003b16cc23',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/86f98a3c-6097-4ab5-baa5-56003b16cc23',
      },
    },
    {
      id: 187,
      name: 'Swaziland',
      flag: {
        mediaType: 1,
        path: 'country/9a05e641-fc13-4dff-88af-642ca5d9fddb',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/9a05e641-fc13-4dff-88af-642ca5d9fddb',
      },
    },
    {
      id: 188,
      name: 'Sweden',
      flag: {
        mediaType: 1,
        path: 'country/41520b66-60bd-43c7-bb8f-f83f381da2d1',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/41520b66-60bd-43c7-bb8f-f83f381da2d1',
      },
    },
    {
      id: 225,
      name: 'Switzerland',
      flag: {
        mediaType: 1,
        path: 'country/b4be7f76-13e3-4cd2-86c7-47feaf574097',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/b4be7f76-13e3-4cd2-86c7-47feaf574097',
      },
    },
    {
      id: 189,
      name: 'Syria',
      flag: {
        mediaType: 1,
        path: 'country/5c931329-896c-429a-b658-edc7b60c72f0',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/5c931329-896c-429a-b658-edc7b60c72f0',
      },
    },
    {
      id: 190,
      name: 'Tahiti',
      flag: {
        mediaType: 1,
        path: 'country/ed6ba048-c0db-4dcf-8cbc-b4011a5de780',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ed6ba048-c0db-4dcf-8cbc-b4011a5de780',
      },
    },
    {
      id: 191,
      name: 'Tajikistan',
      flag: {
        mediaType: 1,
        path: 'country/59f24bc0-d6b7-47d7-b51c-2e1f5edf248f',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/59f24bc0-d6b7-47d7-b51c-2e1f5edf248f',
      },
    },
    {
      id: 192,
      name: 'Tanzania',
      flag: {
        mediaType: 1,
        path: 'country/313b9085-0ca1-4a09-928e-fbe5cf458da1',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/313b9085-0ca1-4a09-928e-fbe5cf458da1',
      },
    },
    {
      id: 193,
      name: 'Thailand',
      flag: {
        mediaType: 1,
        path: 'country/425c1887-b6a9-4fcd-be6b-4263698d9e83',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/425c1887-b6a9-4fcd-be6b-4263698d9e83',
      },
    },
    {
      id: 194,
      name: 'Timor-Leste',
      flag: {
        mediaType: 1,
        path: 'country/13ae53f2-10ce-4bef-814e-cbdd60cb9a6d',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/13ae53f2-10ce-4bef-814e-cbdd60cb9a6d',
      },
    },
    {
      id: 195,
      name: 'Togo',
      flag: {
        mediaType: 1,
        path: 'country/84c14e46-0575-4ca0-86a6-104e3acf4739',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/84c14e46-0575-4ca0-86a6-104e3acf4739',
      },
    },
    {
      id: 196,
      name: 'Tonga',
      flag: {
        mediaType: 1,
        path: 'country/7c310e52-7bbd-4e29-864b-362c528e4c79',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/7c310e52-7bbd-4e29-864b-362c528e4c79',
      },
    },
    {
      id: 197,
      name: 'Trinidad and Tobago',
      flag: {
        mediaType: 1,
        path: 'country/5715b0c1-a956-4ef3-a24c-36558b55749e',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/5715b0c1-a956-4ef3-a24c-36558b55749e',
      },
    },
    {
      id: 198,
      name: 'Tunisia',
      flag: {
        mediaType: 1,
        path: 'country/6ee9e5b4-76ff-4211-9c22-254176ccf33e',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/6ee9e5b4-76ff-4211-9c22-254176ccf33e',
      },
    },
    {
      id: 199,
      name: 'Turkey',
      flag: {
        mediaType: 1,
        path: 'country/593bb582-4c37-4221-a97a-23eff4304f06',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/593bb582-4c37-4221-a97a-23eff4304f06',
      },
    },
    {
      id: 200,
      name: 'Turkmenistan',
      flag: {
        mediaType: 1,
        path: 'country/a4b5ed8c-c216-4c06-a895-6353edbc2629',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a4b5ed8c-c216-4c06-a895-6353edbc2629',
      },
    },
    {
      id: 201,
      name: 'Turks and Caicos Islands',
      flag: {
        mediaType: 1,
        path: 'country/9a3f7aac-c4ad-4748-9918-c56af213aeba',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/9a3f7aac-c4ad-4748-9918-c56af213aeba',
      },
    },
    {
      id: 202,
      name: 'Tuvalu',
      flag: {
        mediaType: 1,
        path: 'country/71c15bdc-1a3f-4a42-842a-08303d766cea',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/71c15bdc-1a3f-4a42-842a-08303d766cea',
      },
    },
    {
      id: 203,
      name: 'Uganda',
      flag: {
        mediaType: 1,
        path: 'country/0eb580a6-c9df-4067-aad9-6fe920889845',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/0eb580a6-c9df-4067-aad9-6fe920889845',
      },
    },
    {
      id: 204,
      name: 'Ukraine',
      flag: {
        mediaType: 1,
        path: 'country/82251102-103c-4dd3-9377-5c240bb18577',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/82251102-103c-4dd3-9377-5c240bb18577',
      },
    },
    {
      id: 205,
      name: 'United Arab Emirates',
      flag: {
        mediaType: 1,
        path: 'country/57304314-76d8-43e9-95a7-61632fbef183',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/57304314-76d8-43e9-95a7-61632fbef183',
      },
    },
    {
      id: 207,
      name: 'Uruguay',
      flag: {
        mediaType: 1,
        path: 'country/0dca16c5-a173-44b3-a1b4-afabda47a41e',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/0dca16c5-a173-44b3-a1b4-afabda47a41e',
      },
    },
    {
      id: 208,
      name: 'US Virgin Islands',
      flag: {
        mediaType: 1,
        path: 'country/1bb154ea-27f2-4e05-80fc-5130177368f7',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/1bb154ea-27f2-4e05-80fc-5130177368f7',
      },
    },
    {
      id: 209,
      name: 'Uzbekistan',
      flag: {
        mediaType: 1,
        path: 'country/a1d7f727-1ca1-44e6-a511-af5dc5f7655b',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/a1d7f727-1ca1-44e6-a511-af5dc5f7655b',
      },
    },
    {
      id: 210,
      name: 'Vanuatu',
      flag: {
        mediaType: 1,
        path: 'country/cee03c41-192c-4bd2-98d4-aebc53f89a98',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/cee03c41-192c-4bd2-98d4-aebc53f89a98',
      },
    },
    {
      id: 211,
      name: 'Vietnam',
      flag: {
        mediaType: 1,
        path: 'country/ee65efd1-c4dc-4082-b08c-0a7ba7fa1bd4',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/ee65efd1-c4dc-4082-b08c-0a7ba7fa1bd4',
      },
    },
    {
      id: 213,
      name: 'Yemen',
      flag: {
        mediaType: 1,
        path: 'country/5e4b3193-da14-4bf9-8945-05e0f826172f',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/5e4b3193-da14-4bf9-8945-05e0f826172f',
      },
    },
    {
      id: 214,
      name: 'Zambia',
      flag: {
        mediaType: 1,
        path: 'country/d04fd3a9-a83e-4d6f-9925-026bf1b9d58c',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/d04fd3a9-a83e-4d6f-9925-026bf1b9d58c',
      },
    },
    {
      id: 215,
      name: 'Zanzibar',
      flag: {
        mediaType: 1,
        path: 'country/0bacc80a-d1c3-480d-b142-20ada5597689',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/0bacc80a-d1c3-480d-b142-20ada5597689',
      },
    },
    {
      id: 216,
      name: 'Zimbabwe',
      flag: {
        mediaType: 1,
        path: 'country/3a11f89b-baa4-47a2-b111-95df951e4bb9',
        url: 'https://aiscout-staging.s3.amazonaws.com/country/3a11f89b-baa4-47a2-b111-95df951e4bb9',
      },
    },
    {
      id: 226,
      name: 'International',
      flag: {
        mediaType: 1,
        path: '',
        url: 'https://aiscout-staging.s3.amazonaws.com/',
      },
    },
  ],
};

export default countriesJSON;
