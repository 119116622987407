const proClubBioDetailsJSON = {
  proClubBio: {
    title: 'ABOUT CHELSEA',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed suscipit ultrices lacus a blandit. Quisque rhoncus sapien in justo condimentum laoreet. Ut ac magna sit amet magna consectetur placerat a vel sem. Donec posuere lacus id porta placerat. Suspendisse volutpat est sed mollis vehicula. Integer aliquam tempor felis, tempor cursus ligula mattis sit amet.',
    twitterUrl: 'https://www.twitter.com',
    facebookUrl: 'https://www.facebook.com',
    youtubeUrl: 'https://www.youtube.com',
    instagramUrl: 'https://www.instagram.com',
  },
  hyperlinks: [
    {
      id: 283, // id must be empty when you are creating new resource
      name: 'Website',
      valueDisplayText: 'www.chelseafc.com',
      value: 'https://www.chelseafc.com/en',
      hyperlinkType: 1,
    },
    {
      id: 285,
      name: 'News',
      valueDisplayText: 'chelseafc.com/en/news',
      value: 'https://www.chelseafc.com/en/news',
      hyperlinkType: 1,
    },
    {
      id: 286,
      name: 'Shop 123',
      valueDisplayText: 'chelseamegastore.com',
      value: 'https://www.chelseamegastore.com/en/',
      hyperlinkType: 1,
    },
  ],
  venues: [
    {
      id: 108,
      bioTitle: 'STADIUM 123',
      address: 'Stamford Bridge, Fulham Rd, London SW6 1HS',
      statistics: [
        {
          id: 351,
          name: 'Nickname',
          value: 'The Blues, The Pensioners',
        },
        {
          id: 349,
          name: 'Greatest Player',
          value: 'Frank Lampard',
        },
        {
          id: 350,
          name: 'Main Sponsors',
          value: 'Nike, 3, Trivago',
        },
      ],
    },
    {
      id: 109,
      bioTitle: 'THE ACADEMY',
      address: "65 Stoke Road, Stoke D'Abernon, Surrey KT11",
      statistics: [
        {
          name: 'Category',
          value: 'One',
        },
        {
          id: 354,
          name: 'Greatest Player',
          value: 'John Terry',
        },
        {
          name: 'League',
          value: 'Premier League',
        },
      ],
    },
  ],
};

export default proClubBioDetailsJSON;
