export const playerCompletedReportJSON = {
  meta: {
    scoutingPlayers: [
      {
        id: 1442,
        name: 'test userabcd',
        player: {
          id: 18334,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379216&Signature=VGszThEark0PCI-kJBp3wVCzzO49Woyd7HNWZf9m39lFJRHcxku5X31RWEiVVwFNyyr03caYtGWVZbJ1ZYfTvY1Io3mNAzAwof3RxK~DYPKoe-9nIs08Vr2rohN9FOBElkp1laFlISIM6Qh8ssRnxQrtExcK~0G5DM~zR7i-eRokZCblsLH0rqWIfNZckXvBmh3mE1vQSZAssab0ZYJSzbhNxT0Lw6IBiLupKgYbY67hMgxU0bXlRoDFiPkDUxNqm5n3An1rP7vBViYbG~2rlO1apIc1x8O2SYsD7fXpYPlmnk4bRAejs-Q8Ha49lBv6m3ijlHNxcbS3z9I-aVPOwA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
          primaryPosition: {
            id: 8,
            name: 'Left Midfield',
          },
        },
      },
      {
        id: 1446,
        name: 'test userabcd',
        player: {
          id: 18334,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379216&Signature=VGszThEark0PCI-kJBp3wVCzzO49Woyd7HNWZf9m39lFJRHcxku5X31RWEiVVwFNyyr03caYtGWVZbJ1ZYfTvY1Io3mNAzAwof3RxK~DYPKoe-9nIs08Vr2rohN9FOBElkp1laFlISIM6Qh8ssRnxQrtExcK~0G5DM~zR7i-eRokZCblsLH0rqWIfNZckXvBmh3mE1vQSZAssab0ZYJSzbhNxT0Lw6IBiLupKgYbY67hMgxU0bXlRoDFiPkDUxNqm5n3An1rP7vBViYbG~2rlO1apIc1x8O2SYsD7fXpYPlmnk4bRAejs-Q8Ha49lBv6m3ijlHNxcbS3z9I-aVPOwA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
          primaryPosition: {
            id: 8,
            name: 'Left Midfield',
          },
        },
      },
      {
        id: 1447,
        name: 'test userabcd',
        player: {
          id: 18334,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379216&Signature=VGszThEark0PCI-kJBp3wVCzzO49Woyd7HNWZf9m39lFJRHcxku5X31RWEiVVwFNyyr03caYtGWVZbJ1ZYfTvY1Io3mNAzAwof3RxK~DYPKoe-9nIs08Vr2rohN9FOBElkp1laFlISIM6Qh8ssRnxQrtExcK~0G5DM~zR7i-eRokZCblsLH0rqWIfNZckXvBmh3mE1vQSZAssab0ZYJSzbhNxT0Lw6IBiLupKgYbY67hMgxU0bXlRoDFiPkDUxNqm5n3An1rP7vBViYbG~2rlO1apIc1x8O2SYsD7fXpYPlmnk4bRAejs-Q8Ha49lBv6m3ijlHNxcbS3z9I-aVPOwA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
          primaryPosition: {
            id: 8,
            name: 'Left Midfield',
          },
        },
      },
      {
        id: 1460,
        name: 'test userabcd',
        player: {
          id: 18334,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379216&Signature=VGszThEark0PCI-kJBp3wVCzzO49Woyd7HNWZf9m39lFJRHcxku5X31RWEiVVwFNyyr03caYtGWVZbJ1ZYfTvY1Io3mNAzAwof3RxK~DYPKoe-9nIs08Vr2rohN9FOBElkp1laFlISIM6Qh8ssRnxQrtExcK~0G5DM~zR7i-eRokZCblsLH0rqWIfNZckXvBmh3mE1vQSZAssab0ZYJSzbhNxT0Lw6IBiLupKgYbY67hMgxU0bXlRoDFiPkDUxNqm5n3An1rP7vBViYbG~2rlO1apIc1x8O2SYsD7fXpYPlmnk4bRAejs-Q8Ha49lBv6m3ijlHNxcbS3z9I-aVPOwA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
          primaryPosition: {
            id: 8,
            name: 'Left Midfield',
          },
        },
      },
      {
        id: 1482,
        name: 'test userabcd',
        player: {
          id: 18334,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379216&Signature=VGszThEark0PCI-kJBp3wVCzzO49Woyd7HNWZf9m39lFJRHcxku5X31RWEiVVwFNyyr03caYtGWVZbJ1ZYfTvY1Io3mNAzAwof3RxK~DYPKoe-9nIs08Vr2rohN9FOBElkp1laFlISIM6Qh8ssRnxQrtExcK~0G5DM~zR7i-eRokZCblsLH0rqWIfNZckXvBmh3mE1vQSZAssab0ZYJSzbhNxT0Lw6IBiLupKgYbY67hMgxU0bXlRoDFiPkDUxNqm5n3An1rP7vBViYbG~2rlO1apIc1x8O2SYsD7fXpYPlmnk4bRAejs-Q8Ha49lBv6m3ijlHNxcbS3z9I-aVPOwA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
          primaryPosition: {
            id: 8,
            name: 'Left Midfield',
          },
        },
      },
      {
        id: 1483,
        name: 'test userabcd',
        player: {
          id: 18334,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379216&Signature=VGszThEark0PCI-kJBp3wVCzzO49Woyd7HNWZf9m39lFJRHcxku5X31RWEiVVwFNyyr03caYtGWVZbJ1ZYfTvY1Io3mNAzAwof3RxK~DYPKoe-9nIs08Vr2rohN9FOBElkp1laFlISIM6Qh8ssRnxQrtExcK~0G5DM~zR7i-eRokZCblsLH0rqWIfNZckXvBmh3mE1vQSZAssab0ZYJSzbhNxT0Lw6IBiLupKgYbY67hMgxU0bXlRoDFiPkDUxNqm5n3An1rP7vBViYbG~2rlO1apIc1x8O2SYsD7fXpYPlmnk4bRAejs-Q8Ha49lBv6m3ijlHNxcbS3z9I-aVPOwA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
          primaryPosition: {
            id: 8,
            name: 'Left Midfield',
          },
        },
      },
      {
        id: 1488,
        name: 'test userabcd',
        player: {
          id: 18335,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379216&Signature=VGszThEark0PCI-kJBp3wVCzzO49Woyd7HNWZf9m39lFJRHcxku5X31RWEiVVwFNyyr03caYtGWVZbJ1ZYfTvY1Io3mNAzAwof3RxK~DYPKoe-9nIs08Vr2rohN9FOBElkp1laFlISIM6Qh8ssRnxQrtExcK~0G5DM~zR7i-eRokZCblsLH0rqWIfNZckXvBmh3mE1vQSZAssab0ZYJSzbhNxT0Lw6IBiLupKgYbY67hMgxU0bXlRoDFiPkDUxNqm5n3An1rP7vBViYbG~2rlO1apIc1x8O2SYsD7fXpYPlmnk4bRAejs-Q8Ha49lBv6m3ijlHNxcbS3z9I-aVPOwA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
        },
      },
      {
        id: 1493,
        name: 'anfaal cheriakam',
        player: {
          id: 16547,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379216&Signature=VGszThEark0PCI-kJBp3wVCzzO49Woyd7HNWZf9m39lFJRHcxku5X31RWEiVVwFNyyr03caYtGWVZbJ1ZYfTvY1Io3mNAzAwof3RxK~DYPKoe-9nIs08Vr2rohN9FOBElkp1laFlISIM6Qh8ssRnxQrtExcK~0G5DM~zR7i-eRokZCblsLH0rqWIfNZckXvBmh3mE1vQSZAssab0ZYJSzbhNxT0Lw6IBiLupKgYbY67hMgxU0bXlRoDFiPkDUxNqm5n3An1rP7vBViYbG~2rlO1apIc1x8O2SYsD7fXpYPlmnk4bRAejs-Q8Ha49lBv6m3ijlHNxcbS3z9I-aVPOwA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
        },
      },
      {
        id: 1234,
        name: 'Venura Calcey',
      },
    ],
    offset: 0,
    limit: 1000,
  },
  items: [
    {
      id: 820,
      title: 'qqqASas',
      created: '2023-02-16T03:58:42.1662139Z',
      scoutingPlayerIds: [1493],
    },
    {
      id: 815,
      title: 'Test note 9',
      created: '2023-02-15T03:30:47.6171277Z',
      scoutingPlayerIds: [1488],
    },
    {
      id: 811,
      title: 'Test note 8',
      created: '2023-02-10T07:17:10.2838257Z',
      scoutingPlayerIds: [1483],
    },
    {
      id: 810,
      title: 'Test note 5',
      created: '2023-02-10T06:51:41.3007784Z',
      scoutingPlayerIds: [1482],
    },
    {
      id: 801,
      title: 'Longest name for a scouting note',
      created: '2023-01-25T06:17:53.348334Z',
      scoutingPlayerIds: [1460],
    },
    {
      id: 794,
      title: 'Title update',
      created: '2023-01-18T09:15:06.8012564Z',
      scoutingPlayerIds: [1447],
    },
    {
      id: 793,
      title: 'Title update',
      created: '2023-01-18T06:54:14.9233021Z',
      scoutingPlayerIds: [1446],
    },
    {
      id: 790,
      title: 'Longest name for a scouting note 2',
      created: '2023-01-12T12:02:18.4921067Z',
      scoutingPlayerIds: [1234],
    },
    {
      id: 789,
      title: 'Longest name for a scouting note 3',
      created: '2023-01-12T12:02:18.4921067Z',
      scoutingPlayerIds: [1234],
    },
  ],
};

export const playerInProgressReportJSON = {
  meta: {
    scoutingPlayers: [
      {
        id: 1494,
        name: 'Ben Chesters',
        player: {
          id: 10702,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379189&Signature=hhe1d4GQGkVzpxkTKpps18DIUMBMlFY1XVCU0fwjfppYCCL3NhH3sc~7YBr6GC9OP9uPdV~N~PyL59tiGKzoaaVQdBKdR500SSATjKx7WFZp18FvyQ3Iuzxu2sUNsoNJfmurFsEvvLyMvYaz291FGw1ChPnGorqns4ISX5NxIrZmawjapRcm1zuyAa25T2vOjvlAMiheHayY9d-jtWYwjoCe4MwO7DsWxbiHJf3YLfRcR1l32SXs5qTrjnGMMDmGSlHP3fbNs61dZIi1MxoxGCWZWNXdgrMPS0E8d2ry7QRXdkxNzOfTwOS1CisbClvrDFsIR9d6maBxtDrURvy5CQ__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
        },
      },
      {
        id: 1495,
        name: 'anujchetry64@gmail.com anujchetry64@gmail.com',
        player: {
          id: 14702,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379189&Signature=hhe1d4GQGkVzpxkTKpps18DIUMBMlFY1XVCU0fwjfppYCCL3NhH3sc~7YBr6GC9OP9uPdV~N~PyL59tiGKzoaaVQdBKdR500SSATjKx7WFZp18FvyQ3Iuzxu2sUNsoNJfmurFsEvvLyMvYaz291FGw1ChPnGorqns4ISX5NxIrZmawjapRcm1zuyAa25T2vOjvlAMiheHayY9d-jtWYwjoCe4MwO7DsWxbiHJf3YLfRcR1l32SXs5qTrjnGMMDmGSlHP3fbNs61dZIi1MxoxGCWZWNXdgrMPS0E8d2ry7QRXdkxNzOfTwOS1CisbClvrDFsIR9d6maBxtDrURvy5CQ__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
        },
      },
      {
        id: 1496,
        name: 'archer brandt',
        player: {
          id: 3715,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379189&Signature=hhe1d4GQGkVzpxkTKpps18DIUMBMlFY1XVCU0fwjfppYCCL3NhH3sc~7YBr6GC9OP9uPdV~N~PyL59tiGKzoaaVQdBKdR500SSATjKx7WFZp18FvyQ3Iuzxu2sUNsoNJfmurFsEvvLyMvYaz291FGw1ChPnGorqns4ISX5NxIrZmawjapRcm1zuyAa25T2vOjvlAMiheHayY9d-jtWYwjoCe4MwO7DsWxbiHJf3YLfRcR1l32SXs5qTrjnGMMDmGSlHP3fbNs61dZIi1MxoxGCWZWNXdgrMPS0E8d2ry7QRXdkxNzOfTwOS1CisbClvrDFsIR9d6maBxtDrURvy5CQ__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
        },
      },
      {
        id: 1497,
        name: 'anfaal cheriakam',
        player: {
          id: 16547,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage',
            mediaType: 2,
            path: 'user/Player/DefaultProfileImage',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/Player/DefaultProfileImage?Expires=1681379189&Signature=hhe1d4GQGkVzpxkTKpps18DIUMBMlFY1XVCU0fwjfppYCCL3NhH3sc~7YBr6GC9OP9uPdV~N~PyL59tiGKzoaaVQdBKdR500SSATjKx7WFZp18FvyQ3Iuzxu2sUNsoNJfmurFsEvvLyMvYaz291FGw1ChPnGorqns4ISX5NxIrZmawjapRcm1zuyAa25T2vOjvlAMiheHayY9d-jtWYwjoCe4MwO7DsWxbiHJf3YLfRcR1l32SXs5qTrjnGMMDmGSlHP3fbNs61dZIi1MxoxGCWZWNXdgrMPS0E8d2ry7QRXdkxNzOfTwOS1CisbClvrDFsIR9d6maBxtDrURvy5CQ__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
        },
      },
      {
        id: 1692,
        name: 'Ross Clinton',
        player: {
          id: 18335,
          profileImage: {
            thumbnail:
              'https://dhie7xdg4ofjy.cloudfront.net/user/d543990b-f6d6-4559-a098-28cb6b9db3e8/b47d0750-640c-11eb-bd25-0b3e3fd8488d.img-thumbnail-100.png',
            mediaType: 2,
            path: 'user/d543990b-f6d6-4559-a098-28cb6b9db3e8/b47d0750-640c-11eb-bd25-0b3e3fd8488d.img',
            url: 'https://dhie7xdg4ofjy.cloudfront.net/user/d543990b-f6d6-4559-a098-28cb6b9db3e8/b47d0750-640c-11eb-bd25-0b3e3fd8488d.img?Expires=1681379189&Signature=NpSYtIEOzpCKXbp2nJjnuA0noZXb9AZHfL2Z8t6wUS4SCsz1q6q5mSF0~g1TlfAiFiz23WjumER0ld-B7aa7-jeB1NWdM~MX0cdrQPqI9DZN9V98EhTqbqTAWRoqmG93mhjKiY3QCrTZUu4CPR7m-aXqjoxFG5sksJybD4Eict3bp7ZuI1GVxv08auD59eDZ-IqNG1thUGgULk3CPCmBjh-Gdyq7iEI-COieMLzq0mp9WWC353mF2b0SEAAWZTCRWrN3CPfZ7d5B4HlOimjooo1qZNYQMGLLsI3mo7Sxi~xyi6mcpyxjIsvHvsotv4TAEP1hwm5mKEcobbhk4E34aA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
          },
        },
      },
      {
        id: 1234,
        name: 'Venura Calcey',
      },
    ],
    offset: 0,
    limit: 1000,
  },
  items: [
    {
      id: 972,
      title: 'Test note 10',
      created: '2023-04-03T03:00:43.6823951Z',
      scoutingPlayerIds: [1692],
    },
    {
      id: 824,
      title: 'a',
      created: '2023-02-16T10:30:00.4516635Z',
      scoutingPlayerIds: [1497],
    },
    {
      id: 823,
      title: 'asdasdasd',
      created: '2023-02-16T05:17:14.416902Z',
      scoutingPlayerIds: [1496],
    },
    {
      id: 822,
      title: 'asd',
      created: '2023-02-16T05:16:26.6268608Z',
      scoutingPlayerIds: [1495],
    },
    {
      id: 821,
      title: 'xxx',
      created: '2023-02-16T04:21:59.9206849Z',
      scoutingPlayerIds: [1494],
    },
    {
      id: 820,
      title: 'testing note 1',
      created: '2023-02-16T04:21:59.9206849Z',
      scoutingPlayerIds: [1234],
    },
  ],
};
