import { red } from '@mui/material/colors';

declare module '@mui/material/styles/createPalette' {
  interface ExtraPalette {
    trialType: {
      cognitive: string;
      physical: string;
      benchmark: string;
    };
    leaderLine: {
      cognitive: string;
      physical: string;
      default: string;
    };
    scatterChart: {
      primary: string;
      secondary: string;
    };
    typography: {
      secondary: string;
    };
  }

  interface Palette extends ExtraPalette {}
  interface PaletteOptions extends ExtraPalette {}
}

const colors = {
  primary: {
    main: '#4BB480',
  },
  secondary: {
    main: '#FFFFFF',
  },
  error: {
    main: red.A400,
  },
  success: {
    main: '#4BB480',
  },
  trialType: {
    cognitive: '#FFBA06',
    physical: '#0BEFF2',
    benchmark: '#B2B4B6',
  },
  leaderLine: {
    cognitive: '#FFBA06',
    physical: '#0BEFF2',
    default: '#D9D9D9',
  },
  scatterChart: {
    primary: '#DCDCDC',
    secondary: 'black',
  },
  typography: {
    secondary: '#767676',
  },
};

export default colors;
