import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import { env } from 'env.mjs';

import { type AppRouter } from 'server/routers';

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 * @deprecated Use `RouterInputs` from `helpers/trpc/shared` or the re-exports
 * from `helpers/trpc/[Your_Context]` instead.
 */
export type InferTRPCInputTypes = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 * @deprecated Use `RouterOutputs` from `helpers/trpc/shared` or the re-exports
 * from `helpers/trpc/[Your_Context]` instead.
 */
export type InferTRPCOutputTypes = inferRouterOutputs<AppRouter>;

export function getBaseUrl() {
  if (typeof window !== 'undefined') return window.location.origin; // browser should use relative url
  if (env.BASE_PATH) return env.BASE_PATH; // Use the base URL if there is one set in the environment
  if (env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
}
