const signUpTiersJSON = {
  tier1: {
    id: 17,
    popUpTitle: 'Under 13?',
    popUpDescription:
      "You're too young to have an account, a parent or guardian will need to set up an account.",
    popUpProceedButtonText: 'Proceed',
    signUpTierType: 1,
    termsAgreement: {
      id: 5,
      resourceUrl: 'https://aiscout.io/uk-terms-and-conditions/',
      blurb:
        'You understand and accept that, although the function of the App is to allow you to upload certain information about yourself and to upload technical data demonstrating your skills and aptitude at a given sport.',
      buttonText: 'Read',
      acceptButtonText: 'Read',
    },
  },

  tier2: {
    id: 18,
    popUpTitle: 'Between 13 and 16?',
    popUpDescription: 'You need guardian consent',
    popUpProceedButtonText: 'Proceed',
    signUpTierType: 2,
    termsAgreement: {
      id: 9,
      resourceUrl: 'https://aiscout.io/uk-terms-and-conditions/',
      blurb:
        'Under data protection legislation, we are required to provide you with details of who we are, how and why we process your personal information, and ro explain your rights and how to exercise them..',
      buttonText: 'Read',
      acceptButtonText: 'Read',
    },
  },

  tier3: {
    id: 19,
    popUpTitle: 'Over 17?',
    popUpDescription: 'You can access without any restrictions',
    popUpProceedButtonText: 'Proceed',
    signUpTierType: 3,
    termsAgreement: {
      id: 12,
      resourceUrl: 'https://aiscout.io/uk-terms-and-conditions/',
      blurb:
        'We may transfer our rights and obligations under these terms to another party. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.',
      buttonText: 'Read',
      acceptButtonText: 'Read',
    },
  },
};

export default signUpTiersJSON;
