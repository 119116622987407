'use client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (devTools) => ({
      default: devTools.ReactQueryDevtools,
    })
  )
);

export const ReactQueryDevToolsClientComponent = ({}) => {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-expect-error - We are adding this function to the window object as a
    // hidden flag!
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <>
      <ReactQueryDevtools buttonPosition="bottom-left" />
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction buttonPosition="bottom-left" />
        </React.Suspense>
      )}
    </>
  );
};
