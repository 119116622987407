const countiesJSON = {
  meta: {
    countries: [],
    offset: 0,
    limit: 10,
  },
  items: [
    {
      id: 204,
      name: 'Aberdeen',
      countryId: 4,
    },
    {
      id: 202,
      name: 'Aberdeenshire',
      countryId: 4,
    },
    {
      id: 104,
      name: 'Abia',
      countryId: 139,
    },
    {
      id: 105,
      name: 'Adamawa',
      countryId: 139,
    },
    {
      id: 178,
      name: 'Ahafo Region',
      countryId: 75,
    },
    {
      id: 106,
      name: 'Akwa Ibom',
      countryId: 139,
    },
    {
      id: 49,
      name: 'Alabama',
      countryId: 206,
    },
    {
      id: 50,
      name: 'Alaska',
      countryId: 206,
    },
    {
      id: 195,
      name: 'Alberta',
      countryId: 35,
    },
    {
      id: 107,
      name: 'Anambra',
      countryId: 139,
    },
    {
      id: 267,
      name: 'Andhra Pradesh',
      countryId: 90,
    },
    {
      id: 213,
      name: 'Angus',
      countryId: 4,
    },
    {
      id: 230,
      name: 'Antrim',
      countryId: 3,
    },
    {
      id: 223,
      name: 'Argyll and Bute',
      countryId: 4,
    },
    {
      id: 51,
      name: 'Arizona',
      countryId: 206,
    },
    {
      id: 52,
      name: 'Arkansas',
      countryId: 206,
    },
    {
      id: 231,
      name: 'Armagh',
      countryId: 3,
    },
    {
      id: 268,
      name: 'Arunachal Pradesh',
      countryId: 90,
    },
    {
      id: 269,
      name: 'Assam',
      countryId: 90,
    },
    {
      id: 164,
      name: 'Auvergne-Rhône-Alpes',
      countryId: 68,
    },
    {
      id: 108,
      name: 'Bauchi',
      countryId: 139,
    },
    {
      id: 109,
      name: 'Bayelsa',
      countryId: 139,
    },
    {
      id: 2,
      name: 'Bedfordshire',
      countryId: 1,
    },
    {
      id: 110,
      name: 'Benue',
      countryId: 139,
    },
    {
      id: 1,
      name: 'Berkshire',
      countryId: 1,
    },
    {
      id: 270,
      name: 'Bihar',
      countryId: 90,
    },
    {
      id: 152,
      name: 'Blekinge County',
      countryId: 188,
    },
    {
      id: 177,
      name: 'Bono East Region',
      countryId: 75,
    },
    {
      id: 176,
      name: 'Bono Region',
      countryId: 75,
    },
    {
      id: 111,
      name: 'Borno',
      countryId: 139,
    },
    {
      id: 173,
      name: 'Bourgogne-Franche-Comté',
      countryId: 68,
    },
    {
      id: 3,
      name: 'Bristol',
      countryId: 1,
    },
    {
      id: 193,
      name: 'British Columbia',
      countryId: 35,
    },
    {
      id: 172,
      name: 'Brittany',
      countryId: 68,
    },
    {
      id: 4,
      name: 'Buckinghamshire',
      countryId: 1,
    },
    {
      id: 53,
      name: 'California',
      countryId: 206,
    },
    {
      id: 5,
      name: 'Cambridgeshire',
      countryId: 1,
    },
    {
      id: 232,
      name: 'Carlow',
      countryId: 2,
    },
    {
      id: 233,
      name: 'Cavan',
      countryId: 2,
    },
    {
      id: 179,
      name: 'Central',
      countryId: 75,
    },
    {
      id: 174,
      name: 'Centre-Val de Loire',
      countryId: 68,
    },
    {
      id: 6,
      name: 'Cheshire',
      countryId: 1,
    },
    {
      id: 271,
      name: 'Chhattisgarh',
      countryId: 90,
    },
    {
      id: 7,
      name: 'City of London',
      countryId: 1,
    },
    {
      id: 225,
      name: 'Clackmannanshire',
      countryId: 4,
    },
    {
      id: 234,
      name: 'Clare',
      countryId: 2,
    },
    {
      id: 54,
      name: 'Colorado',
      countryId: 206,
    },
    {
      id: 55,
      name: 'Connecticut',
      countryId: 206,
    },
    {
      id: 235,
      name: 'Cork',
      countryId: 2,
    },
    {
      id: 8,
      name: 'Cornwall',
      countryId: 1,
    },
    {
      id: 175,
      name: 'Corsica',
      countryId: 68,
    },
    {
      id: 13,
      name: 'County Durham Durham',
      countryId: 1,
    },
    {
      id: 112,
      name: 'Cross River',
      countryId: 139,
    },
    {
      id: 9,
      name: 'Cumbria',
      countryId: 1,
    },
    {
      id: 159,
      name: 'Dalarna County',
      countryId: 188,
    },
    {
      id: 56,
      name: 'Delaware',
      countryId: 206,
    },
    {
      id: 113,
      name: 'Delta',
      countryId: 139,
    },
    {
      id: 10,
      name: 'Derbyshire',
      countryId: 1,
    },
    {
      id: 11,
      name: 'Devon',
      countryId: 1,
    },
    {
      id: 236,
      name: 'Donegal',
      countryId: 2,
    },
    {
      id: 12,
      name: 'Dorset',
      countryId: 1,
    },
    {
      id: 237,
      name: 'Down',
      countryId: 3,
    },
    {
      id: 238,
      name: 'Dublin',
      countryId: 2,
    },
    {
      id: 209,
      name: 'Dumfries and Galloway',
      countryId: 4,
    },
    {
      id: 239,
      name: 'Dún Laoghaire–Rathdown',
      countryId: 2,
    },
    {
      id: 210,
      name: 'Dundee',
      countryId: 4,
    },
    {
      id: 212,
      name: 'East Ayrshire',
      countryId: 4,
    },
    {
      id: 216,
      name: 'East Dunbartonshire',
      countryId: 4,
    },
    {
      id: 217,
      name: 'East Lothian',
      countryId: 4,
    },
    {
      id: 219,
      name: 'East Renfrewshire',
      countryId: 4,
    },
    {
      id: 14,
      name: 'East Riding of Yorkshire',
      countryId: 1,
    },
    {
      id: 15,
      name: 'East Sussex',
      countryId: 1,
    },
    {
      id: 180,
      name: 'Eastern',
      countryId: 75,
    },
    {
      id: 114,
      name: 'Ebonyi',
      countryId: 139,
    },
    {
      id: 198,
      name: 'Edinburgh',
      countryId: 4,
    },
    {
      id: 115,
      name: 'Edo',
      countryId: 139,
    },
    {
      id: 116,
      name: 'Ekiti',
      countryId: 139,
    },
    {
      id: 191,
      name: 'England ntl',
      countryId: 1,
    },
    {
      id: 117,
      name: 'Enugu',
      countryId: 139,
    },
    {
      id: 16,
      name: 'Essex',
      countryId: 1,
    },
    {
      id: 207,
      name: 'Falkirk',
      countryId: 4,
    },
    {
      id: 242,
      name: 'Fermanagh',
      countryId: 3,
    },
    {
      id: 199,
      name: 'Fife',
      countryId: 4,
    },
    {
      id: 240,
      name: 'Fingal',
      countryId: 2,
    },
    {
      id: 57,
      name: 'Florida',
      countryId: 206,
    },
    {
      id: 243,
      name: 'Galway',
      countryId: 2,
    },
    {
      id: 160,
      name: 'Gävleborg County',
      countryId: 188,
    },
    {
      id: 58,
      name: 'Georgia',
      countryId: 206,
    },
    {
      id: 197,
      name: 'Glasgow',
      countryId: 4,
    },
    {
      id: 17,
      name: 'Gloucestershire',
      countryId: 1,
    },
    {
      id: 272,
      name: 'Goa',
      countryId: 90,
    },
    {
      id: 118,
      name: 'Gombe',
      countryId: 139,
    },
    {
      id: 151,
      name: 'Gotland County',
      countryId: 188,
    },
    {
      id: 168,
      name: 'Grand Est',
      countryId: 68,
    },
    {
      id: 181,
      name: 'Greater Accra',
      countryId: 75,
    },
    {
      id: 18,
      name: 'Greater London',
      countryId: 1,
    },
    {
      id: 19,
      name: 'Greater Manchester',
      countryId: 1,
    },
    {
      id: 273,
      name: 'Gujarat',
      countryId: 90,
    },
    {
      id: 154,
      name: 'Halland County',
      countryId: 188,
    },
    {
      id: 20,
      name: 'Hampshire',
      countryId: 1,
    },
    {
      id: 274,
      name: 'Haryana',
      countryId: 90,
    },
    {
      id: 165,
      name: 'Hauts-de-France',
      countryId: 68,
    },
    {
      id: 59,
      name: 'Hawaii',
      countryId: 206,
    },
    {
      id: 21,
      name: 'Herefordshire',
      countryId: 1,
    },
    {
      id: 22,
      name: 'Hertfordshire',
      countryId: 1,
    },
    {
      id: 203,
      name: 'Highland',
      countryId: 4,
    },
    {
      id: 275,
      name: 'Himachal Pradesh',
      countryId: 90,
    },
    {
      id: 60,
      name: 'Idaho',
      countryId: 206,
    },
    {
      id: 163,
      name: 'Île-de-France',
      countryId: 68,
    },
    {
      id: 61,
      name: 'Illinois',
      countryId: 206,
    },
    {
      id: 119,
      name: 'Imo',
      countryId: 139,
    },
    {
      id: 295,
      name: 'India ntl',
      countryId: 90,
    },
    {
      id: 62,
      name: 'Indiana',
      countryId: 206,
    },
    {
      id: 141,
      name: 'International',
      countryId: 226,
    },
    {
      id: 224,
      name: 'Inverclyde',
      countryId: 4,
    },
    {
      id: 63,
      name: 'Iowa',
      countryId: 206,
    },
    {
      id: 23,
      name: 'Isle of Wight',
      countryId: 1,
    },
    {
      id: 298,
      name: 'Jammu',
      countryId: 90,
    },
    {
      id: 162,
      name: 'Jämtland County',
      countryId: 188,
    },
    {
      id: 276,
      name: 'Jharkhand',
      countryId: 90,
    },
    {
      id: 120,
      name: 'Jigawa',
      countryId: 139,
    },
    {
      id: 148,
      name: 'Jönköping County',
      countryId: 188,
    },
    {
      id: 121,
      name: 'Kaduna',
      countryId: 139,
    },
    {
      id: 150,
      name: 'Kalmar County',
      countryId: 188,
    },
    {
      id: 122,
      name: 'Kano',
      countryId: 139,
    },
    {
      id: 64,
      name: 'Kansas',
      countryId: 206,
    },
    {
      id: 277,
      name: 'Karnataka',
      countryId: 90,
    },
    {
      id: 299,
      name: 'Kashmir',
      countryId: 90,
    },
    {
      id: 123,
      name: 'Katsina',
      countryId: 139,
    },
    {
      id: 124,
      name: 'Kebbi',
      countryId: 139,
    },
    {
      id: 24,
      name: 'Kent',
      countryId: 1,
    },
    {
      id: 65,
      name: 'Kentucky',
      countryId: 206,
    },
    {
      id: 278,
      name: 'Kerala',
      countryId: 90,
    },
    {
      id: 244,
      name: 'Kerry',
      countryId: 2,
    },
    {
      id: 245,
      name: 'Kildare',
      countryId: 2,
    },
    {
      id: 246,
      name: 'Kilkenny',
      countryId: 2,
    },
    {
      id: 125,
      name: 'Kogi',
      countryId: 139,
    },
    {
      id: 149,
      name: 'Kronoberg County',
      countryId: 188,
    },
    {
      id: 126,
      name: 'Kwara',
      countryId: 139,
    },
    {
      id: 127,
      name: 'Lagos',
      countryId: 139,
    },
    {
      id: 25,
      name: 'Lancashire',
      countryId: 1,
    },
    {
      id: 247,
      name: 'Laois',
      countryId: 2,
    },
    {
      id: 26,
      name: 'Leicestershire',
      countryId: 1,
    },
    {
      id: 248,
      name: 'Leitrim',
      countryId: 2,
    },
    {
      id: 249,
      name: 'Limerick',
      countryId: 2,
    },
    {
      id: 27,
      name: 'Lincolnshire',
      countryId: 1,
    },
    {
      id: 250,
      name: 'Londonderry[nb 3]',
      countryId: 3,
    },
    {
      id: 251,
      name: 'Longford',
      countryId: 2,
    },
    {
      id: 66,
      name: 'Louisiana',
      countryId: 206,
    },
    {
      id: 252,
      name: 'Louth',
      countryId: 2,
    },
    {
      id: 279,
      name: 'Madhya Pradesh',
      countryId: 90,
    },
    {
      id: 280,
      name: 'Maharashtra',
      countryId: 90,
    },
    {
      id: 67,
      name: 'Maine',
      countryId: 206,
    },
    {
      id: 281,
      name: 'Manipur',
      countryId: 90,
    },
    {
      id: 192,
      name: 'Manitoba',
      countryId: 35,
    },
    {
      id: 80,
      name: 'Maryland',
      countryId: 206,
    },
    {
      id: 81,
      name: 'Massachusetts',
      countryId: 206,
    },
    {
      id: 253,
      name: 'Mayo',
      countryId: 2,
    },
    {
      id: 254,
      name: 'Meath',
      countryId: 2,
    },
    {
      id: 282,
      name: 'Meghalaya',
      countryId: 90,
    },
    {
      id: 28,
      name: 'Merseyside',
      countryId: 1,
    },
    {
      id: 82,
      name: 'Michigan',
      countryId: 206,
    },
    {
      id: 221,
      name: 'Midlothian',
      countryId: 4,
    },
    {
      id: 83,
      name: 'Minnesota',
      countryId: 206,
    },
    {
      id: 84,
      name: 'Mississippi',
      countryId: 206,
    },
    {
      id: 85,
      name: 'Missouri',
      countryId: 206,
    },
    {
      id: 283,
      name: 'Mizoram',
      countryId: 90,
    },
    {
      id: 255,
      name: 'Monaghan',
      countryId: 2,
    },
    {
      id: 68,
      name: 'Montana',
      countryId: 206,
    },
    {
      id: 218,
      name: 'Moray',
      countryId: 4,
    },
    {
      id: 226,
      name: 'Na h-Eileanan Siar (Western Isles)',
      countryId: 4,
    },
    {
      id: 284,
      name: 'Nagaland',
      countryId: 90,
    },
    {
      id: 128,
      name: 'Nasarawa',
      countryId: 139,
    },
    {
      id: 69,
      name: 'Nebraska',
      countryId: 206,
    },
    {
      id: 70,
      name: 'Nevada',
      countryId: 206,
    },
    {
      id: 99,
      name: 'New Brunswick',
      countryId: 35,
    },
    {
      id: 71,
      name: 'New Hampshire',
      countryId: 206,
    },
    {
      id: 72,
      name: 'New Jersey',
      countryId: 206,
    },
    {
      id: 73,
      name: 'New Mexico',
      countryId: 206,
    },
    {
      id: 74,
      name: 'New York',
      countryId: 206,
    },
    {
      id: 196,
      name: 'Newfoundland and Labrador',
      countryId: 35,
    },
    {
      id: 129,
      name: 'Niger',
      countryId: 139,
    },
    {
      id: 29,
      name: 'Norfolk',
      countryId: 1,
    },
    {
      id: 171,
      name: 'Normandy',
      countryId: 68,
    },
    {
      id: 144,
      name: 'Norrbotten County',
      countryId: 188,
    },
    {
      id: 211,
      name: 'North Ayrshire',
      countryId: 4,
    },
    {
      id: 75,
      name: 'North Carolina',
      countryId: 206,
    },
    {
      id: 76,
      name: 'North Dakota',
      countryId: 206,
    },
    {
      id: 184,
      name: 'North East',
      countryId: 75,
    },
    {
      id: 200,
      name: 'North Lanarkshire',
      countryId: 4,
    },
    {
      id: 30,
      name: 'North Yorkshire',
      countryId: 1,
    },
    {
      id: 31,
      name: 'Northamptonshire',
      countryId: 1,
    },
    {
      id: 182,
      name: 'Northern',
      countryId: 75,
    },
    {
      id: 265,
      name: 'Northern Ireland ntl',
      countryId: 3,
    },
    {
      id: 32,
      name: 'Northumberland',
      countryId: 1,
    },
    {
      id: 33,
      name: 'Nottinghamshire',
      countryId: 1,
    },
    {
      id: 166,
      name: 'Nouvelle-Aquitaine',
      countryId: 68,
    },
    {
      id: 100,
      name: 'Nova Scotia',
      countryId: 35,
    },
    {
      id: 167,
      name: 'Occitanie',
      countryId: 68,
    },
    {
      id: 285,
      name: 'Odisha',
      countryId: 90,
    },
    {
      id: 256,
      name: 'Offaly',
      countryId: 2,
    },
    {
      id: 130,
      name: 'Ogun',
      countryId: 139,
    },
    {
      id: 77,
      name: 'Ohio',
      countryId: 206,
    },
    {
      id: 78,
      name: 'Oklahoma',
      countryId: 206,
    },
    {
      id: 131,
      name: 'Ondo',
      countryId: 139,
    },
    {
      id: 101,
      name: 'Ontario',
      countryId: 35,
    },
    {
      id: 157,
      name: 'Örebro County',
      countryId: 188,
    },
    {
      id: 79,
      name: 'Oregon',
      countryId: 206,
    },
    {
      id: 228,
      name: 'Orkney Islands',
      countryId: 4,
    },
    {
      id: 147,
      name: 'Östergötland County',
      countryId: 188,
    },
    {
      id: 132,
      name: 'Osun',
      countryId: 139,
    },
    {
      id: 188,
      name: 'Oti',
      countryId: 75,
    },
    {
      id: 34,
      name: 'Oxfordshire',
      countryId: 1,
    },
    {
      id: 133,
      name: 'Oyo',
      countryId: 139,
    },
    {
      id: 170,
      name: 'Pays de la Loire',
      countryId: 68,
    },
    {
      id: 86,
      name: 'Pennsylvania',
      countryId: 206,
    },
    {
      id: 208,
      name: 'Perth and Kinross',
      countryId: 4,
    },
    {
      id: 134,
      name: 'Plateau',
      countryId: 139,
    },
    {
      id: 103,
      name: 'Prince Edward Island',
      countryId: 35,
    },
    {
      id: 169,
      name: "Provence-Alpes-Côte d'Azur",
      countryId: 68,
    },
    {
      id: 286,
      name: 'Punjab',
      countryId: 90,
    },
    {
      id: 102,
      name: 'Quebec',
      countryId: 35,
    },
    {
      id: 287,
      name: 'Rajasthan',
      countryId: 90,
    },
    {
      id: 206,
      name: 'Renfrewshire',
      countryId: 4,
    },
    {
      id: 266,
      name: 'Republic of Ireland ntl',
      countryId: 2,
    },
    {
      id: 87,
      name: 'Rhode Island',
      countryId: 206,
    },
    {
      id: 135,
      name: 'Rivers',
      countryId: 139,
    },
    {
      id: 257,
      name: 'Roscommon',
      countryId: 2,
    },
    {
      id: 35,
      name: 'Rutland',
      countryId: 1,
    },
    {
      id: 296,
      name: 'Rwanda ntl',
      countryId: 160,
    },
    {
      id: 194,
      name: 'Saskatchewan',
      countryId: 35,
    },
    {
      id: 183,
      name: 'Savannah',
      countryId: 75,
    },
    {
      id: 229,
      name: 'Scotland ntl',
      countryId: 4,
    },
    {
      id: 214,
      name: 'Scottish Borders',
      countryId: 4,
    },
    {
      id: 227,
      name: 'Shetland Islands',
      countryId: 4,
    },
    {
      id: 36,
      name: 'Shropshire',
      countryId: 1,
    },
    {
      id: 288,
      name: 'Sikkim',
      countryId: 90,
    },
    {
      id: 153,
      name: 'Skåne County',
      countryId: 188,
    },
    {
      id: 258,
      name: 'Sligo',
      countryId: 2,
    },
    {
      id: 146,
      name: 'Södermanland County',
      countryId: 188,
    },
    {
      id: 136,
      name: 'Sokoto',
      countryId: 139,
    },
    {
      id: 37,
      name: 'Somerset',
      countryId: 1,
    },
    {
      id: 215,
      name: 'South Ayrshire',
      countryId: 4,
    },
    {
      id: 88,
      name: 'South Carolina',
      countryId: 206,
    },
    {
      id: 89,
      name: 'South Dakota',
      countryId: 206,
    },
    {
      id: 241,
      name: 'South Dublin',
      countryId: 2,
    },
    {
      id: 201,
      name: 'South Lanarkshire',
      countryId: 4,
    },
    {
      id: 38,
      name: 'South Yorkshire',
      countryId: 1,
    },
    {
      id: 39,
      name: 'Staffordshire',
      countryId: 1,
    },
    {
      id: 220,
      name: 'Stirling',
      countryId: 4,
    },
    {
      id: 142,
      name: 'Stockholm County',
      countryId: 188,
    },
    {
      id: 40,
      name: 'Suffolk',
      countryId: 1,
    },
    {
      id: 41,
      name: 'Surrey',
      countryId: 1,
    },
    {
      id: 289,
      name: 'Tamil Nadu',
      countryId: 90,
    },
    {
      id: 137,
      name: 'Taraba',
      countryId: 139,
    },
    {
      id: 290,
      name: 'Telangana',
      countryId: 90,
    },
    {
      id: 90,
      name: 'Tennessee',
      countryId: 206,
    },
    {
      id: 91,
      name: 'Texas',
      countryId: 206,
    },
    {
      id: 259,
      name: 'Tipperary',
      countryId: 2,
    },
    {
      id: 291,
      name: 'Tripura',
      countryId: 90,
    },
    {
      id: 42,
      name: 'Tyne and Wear',
      countryId: 1,
    },
    {
      id: 260,
      name: 'Tyrone',
      countryId: 3,
    },
    {
      id: 185,
      name: 'Upper East',
      countryId: 75,
    },
    {
      id: 186,
      name: 'Upper West',
      countryId: 75,
    },
    {
      id: 145,
      name: 'Uppsala County',
      countryId: 188,
    },
    {
      id: 92,
      name: 'Utah',
      countryId: 206,
    },
    {
      id: 292,
      name: 'Uttar Pradesh',
      countryId: 90,
    },
    {
      id: 293,
      name: 'Uttarakhand',
      countryId: 90,
    },
    {
      id: 156,
      name: 'Värmland County',
      countryId: 188,
    },
    {
      id: 143,
      name: 'Västerbotten County',
      countryId: 188,
    },
    {
      id: 161,
      name: 'Västernorrland County',
      countryId: 188,
    },
    {
      id: 158,
      name: 'Västmanland County',
      countryId: 188,
    },
    {
      id: 155,
      name: 'Västra Götaland County',
      countryId: 188,
    },
    {
      id: 93,
      name: 'Vermont',
      countryId: 206,
    },
    {
      id: 94,
      name: 'Virginia',
      countryId: 206,
    },
    {
      id: 187,
      name: 'Volta Region',
      countryId: 75,
    },
    {
      id: 43,
      name: 'Warwickshire',
      countryId: 1,
    },
    {
      id: 95,
      name: 'Washington',
      countryId: 206,
    },
    {
      id: 261,
      name: 'Waterford',
      countryId: 2,
    },
    {
      id: 294,
      name: 'West Bengal',
      countryId: 90,
    },
    {
      id: 222,
      name: 'West Dunbartonshire',
      countryId: 4,
    },
    {
      id: 205,
      name: 'West Lothian',
      countryId: 4,
    },
    {
      id: 44,
      name: 'West Midlands',
      countryId: 1,
    },
    {
      id: 45,
      name: 'West Sussex',
      countryId: 1,
    },
    {
      id: 96,
      name: 'West Virginia',
      countryId: 206,
    },
    {
      id: 46,
      name: 'West Yorkshire',
      countryId: 1,
    },
    {
      id: 190,
      name: 'Western North',
      countryId: 75,
    },
    {
      id: 189,
      name: 'Western Region',
      countryId: 75,
    },
    {
      id: 262,
      name: 'Westmeath',
      countryId: 2,
    },
    {
      id: 263,
      name: 'Wexford',
      countryId: 2,
    },
    {
      id: 264,
      name: 'Wicklow',
      countryId: 2,
    },
    {
      id: 47,
      name: 'Wiltshire',
      countryId: 1,
    },
    {
      id: 97,
      name: 'Wisconsin',
      countryId: 206,
    },
    {
      id: 48,
      name: 'Worcestershire',
      countryId: 1,
    },
    {
      id: 98,
      name: 'Wyoming',
      countryId: 206,
    },
    {
      id: 138,
      name: 'Yobe',
      countryId: 139,
    },
    {
      id: 139,
      name: 'Zamfara',
      countryId: 139,
    },
  ],
};

export default countiesJSON;
