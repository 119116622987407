// Note we should deconstruct falso here to reduce bundle size but for DX I haven't ...
import * as falso from '@ngneat/falso'; // https://ngneat.github.io/falso/
import { rest } from 'msw';
import { z } from 'zod';

import mockData from './mockData/';

export const handlers = [
  // rest.post('*/api/v2/players/register', (req, res, ctx) => {
  //   return res(
  //     ctx.delay('real'),
  //     ctx.status(200),
  //     ctx.json({
  //       accessToken: falso.randUuid(),
  //       name: 'John Doe',
  //       refreshToken: falso.randUuid(),
  //       userId: falso.randUuid(),
  //       playerId: falso.randNumber(),
  //     })
  //   );
  // }),
  // Mock of the login endpoint used on the signup page.
  // If you pass an email of invalid@invalid.com we will say it's the wrong details
  rest.post('*/api/v2/users/login', (req, res, ctx) => {
    // Validate the Inputs
    const body = z
      .object({
        email: z
          .string({ required_error: 'Invalid Email' })
          .email({ message: 'Invalid Email' })
          .min(3)
          .max(100),
        password: z
          .string({ required_error: `'Password' must not be empty.` })
          .min(8)
          .max(100),
      })
      .safeParse(req.body);

    if (body.success) {
      if (body.data.email.toLowerCase() === 'invalid@invalid.com') {
        return res(
          ctx.delay('real'),
          ctx.status(400),
          ctx.json({
            message: 'Invalid email or password',
            codes: ['InvalidEmailOrPassword'],
          })
        );
      } else {
        return res(
          ctx.delay('real'),
          ctx.status(200),
          ctx.json({
            accessToken: falso.randUuid(),
            refreshToken: falso.randUuid(),
            userId: falso.randUuid(),
            roleTypes: [1],
          })
        );
      }
    }
  }),

  rest.post('*/api/v2/users/:userId/switch/players', (req, res, ctx) => {
    return res(
      ctx.delay('real'),
      ctx.status(200),
      ctx.json({
        accessToken: falso.randUuid(),
        refreshToken: falso.randUuid(),
        userId: falso.randUuid(),
        playerId: falso.randNumber({ min: 10000, max: 19999 }),
        isOnboardingComplete: false,
      })
    );
  }),

  // Example mock with inline defined data
  rest.get('*/api/dave', (req, res, ctx) => {
    return res(
      ctx.delay('real'),
      ctx.status(200),
      ctx.json({
        name: 'Dave',
        age: 30,
        green: true,
      })
    );
  }),

  // rest.get('*/api/v2/files/uploadurl', (req, res, ctx) => {
  //   return res(
  //     ctx.delay('real'),
  //     ctx.status(200),
  //     ctx.json({
  //       preSignedUrl: 'https://aiscout/upload/put',
  //       s3ObjectKey: falso.randUuid(),
  //     })
  //   );
  // }),

  // rest.put('*/aiscout/upload/put', (req, res, ctx) => {
  //   return res(ctx.delay('real'), ctx.status(200), ctx.json({}));
  // }),

  rest.put('*/api/v2/players/:playerId/profileimage', (req, res, ctx) => {
    return res(ctx.delay('real'), ctx.status(200), ctx.json({}));
  }),

  // Example mock with an array of data returned using falso
  rest.get('*/api/daves', (req, res, ctx) => {
    return res(
      ctx.delay('real'),
      ctx.status(200),
      ctx.json(
        Array.from({ length: falso.randNumber({ min: 10, max: 100 }) }, () => ({
          name: falso.randFullName(),
          age: falso.randNumber({ min: 13, max: 100 }),
          car: falso.randVehicle(),
        }))
      )
    );
  }),

  // Example mock with countries JSON from actual calcey API.
  rest.get('*/api/v2/countries', (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(), ctx.json(mockData.countriesJSON));
  }),

  rest.post('*/api/v2/players/:playerId/affiliations', (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(), ctx.json({}));
  }),

  // rest.get('*/api/v2/signuptiers/find', (req, res, ctx) => {
  //   let params = new URLSearchParams(req.url.search);
  //   let dateOfBirth = params.get('dateOfBirth');

  //   const age = Math.abs(DateTime.fromISO(dateOfBirth!).diffNow('years').years);

  //   const { tier1, tier2, tier3 } = mockData.signUpTiersJSON;

  //   let tier;

  //   if (age < 13) {
  //     tier = tier1;
  //   } else if (age <= 17) {
  //     tier = tier2;
  //   } else {
  //     tier = tier3;
  //   }

  //   return res(ctx.status(200), ctx.delay(), ctx.json(tier));
  // }),
  rest.get('*/api/v2/positions', (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(), ctx.json(mockData.positionsJSON));
  }),

  // rest.get('*/api/v2/counties', (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(), ctx.json(mockData.countiesJSON));
  // }),

  // rest.get('*/api/v2/countries', (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(), ctx.json(mockData.countriesJSON));
  // }),

  // rest.get('*/api/v2/nationalities', (req, res, ctx) => {
  //   return res(
  //     ctx.status(200),
  //     ctx.delay(),
  //     ctx.json(mockData.nationalitiesJSON)
  //   );
  // }),

  // rest.get('*/api/v2/passportauthorities', (req, res, ctx) => {
  //   return res(
  //     ctx.status(200),
  //     ctx.delay(),
  //     ctx.json(mockData.passportHoldJSON)
  //   );
  // }),
  // TODO: Re-Enable when testing complete? Or switch to real calls permanently!
  // rest.get('*/api/v2/ethnicities', (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(), ctx.json(mockData.ethnicityJSON));
  // }),
  rest.get('*/api/v2/agegroups', (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(), ctx.json(mockData.ageGroupsJSON));
  }),
  // rest.get(
  //   '*/api/v2/playerreportdrafts/:playerReportDraftId',
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.status(200),
  //       ctx.delay(),
  //       ctx.json(mockData.playerReportAnswersCalceyJSON)
  //     );
  //   }
  // ),
  rest.get('*/api/v2/positions', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(),
      ctx.json(mockData.positionsCalceyJSON)
    );
  }),

  // rest.get('*/api/v2/nationalities', (req, res, ctx) => {
  //   return res(
  //     ctx.status(200),
  //     ctx.delay(),
  //     ctx.json(mockData.nationalitiesJSON)
  //   );
  // }),

  rest.get('*/api/v2/proClubs/:clubId/bioDetails', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(),
      ctx.json(mockData.proClubBioDetailsJSON)
    );
  }),

  // rest.get(
  //   '*/api/v2/scoutingnotes/:scoutingNoteId/summary',
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.status(200),
  //       ctx.delay(),
  //       ctx.json(mockData.scoutingNotesSummaryJSON)
  //     );
  //   }
  // ),

  // rest.get(
  //   '*/api/v2/proclubs/:proClubId/scoutingquestions',
  //   (req, res, ctx) => {
  //     const isPlayerBasedQuestion = req.url.search;
  //     if (isPlayerBasedQuestion === '?isPlayerBasedQuestion=true') {
  //       return res(
  //         ctx.status(200),
  //         ctx.delay(),
  //         ctx.json(mockData.technicalQuestionsJSON)
  //       );
  //     } else {
  //       return res(
  //         ctx.status(200),
  //         ctx.delay(),
  //         ctx.json(mockData.generalQuestionsJSON)
  //       );
  //     }
  //   }
  // ),

  // rest.get('*/api/v2/teams/search', (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(), ctx.json(mockData.teamSearchJSON));
  // }),

  // rest.get('*/api/v2/agegroups', (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(), ctx.json(mockData.ageGroupsJSON));
  // }),

  rest.get('*/api/v2/trialcategories', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(),
      ctx.json(mockData.trialCategoriesJSON)
    );
  }),

  rest.get('*/api/v2/proclubs/:proClubId/academies', (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(), ctx.json(mockData.academiesJSON));
  }),

  // rest.patch(
  //   '*/api/v2/scoutingnotes/:scoutingNoteId/status/completed',
  //   (req, res, ctx) => {
  //     return res(ctx.delay(), ctx.status(200), ctx.json({}));
  //   }
  // ),

  // rest.patch(
  //   '*/api/v2/scoutingnotes/:scoutingNoteId/status/created',
  //   (req, res, ctx) => {
  //     return res(ctx.delay(), ctx.status(200), ctx.json({}));
  //   }
  // ),

  // rest.post(
  //   '*/api/v2/scoutingnotes/:scoutingNoteId/scoutingvoicenotes/text/batch',
  //   (req, res, ctx) => {
  //     return res(ctx.delay(), ctx.status(200), ctx.json({}));
  //   }
  // ),

  // rest.post('*/api/v2/scoutingnotes', (req, res, ctx) => {
  //   return res(ctx.delay(), ctx.status(200), ctx.json({ id: 100 }));
  // }),

  // rest.post('*/api/v2/scoutingreports', (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(), ctx.json({}));
  // }),

  // rest.post(
  //   '*/api/v2/scoutingnotes/:scoutingNoteId/scoutingvoicenotes/text',
  //   (req, res, ctx) => {
  //     return res(ctx.delay(), ctx.status(200), ctx.json({ id: 1 }));
  //   }
  // ),

  // rest.get('*/api/v2/trials/turkentries/next', (req, res, ctx) => {
  //   const offset = req.url.searchParams.get('offset');
  //   let relevantData = mockData.nextTurkDetailJSON;

  //   if (offset === '0') {
  //     relevantData = mockData.currentTurkDetailJSON;
  //   }

  //   if (offset === '279') {
  //     relevantData = mockData.turkDetailWithLogsJSON;
  //   }

  //   return res(ctx.status(200), ctx.delay(), ctx.json(relevantData));
  // }),

  // rest.get('*/api/v2/trials/turkentries/count', (req, res, ctx) => {
  //   return res(
  //     ctx.status(200),
  //     ctx.delay(),
  //     ctx.json(mockData.allTurkCountJSON)
  //   );
  // }),

  // rest.patch(
  //   '*/api/v2/playerreportdrafts/:playerReportDraftId',
  //   (req, res, ctx) => {
  //     return res(ctx.status(200), ctx.delay(), ctx.json({}));
  //   }
  // ),

  // rest.put(
  //   '*/api/v2/playerreportdrafts/:playerReportDraftId/responses',
  //   (req, res, ctx) => {
  //     return res(ctx.status(200), ctx.delay(), ctx.json({}));
  //   }
  // ),

  // rest.patch(
  //   '*/api/v2/playerreportdrafts/:playerReportDraftId/notes/:playerReportDraftNoteId',
  //   (req, res, ctx) => {
  //     return res(ctx.status(200), ctx.delay(), ctx.json({}));
  //   }
  // ),

  // rest.put(
  //   '*/api/v2/playerreportdrafts/:playerReportDraftId/notes/:playerReportDraftNoteId/participants',
  //   (req, res, ctx) => {
  //     return res(ctx.status(200), ctx.delay(), ctx.json({}));
  //   }
  // ),

  // rest.delete(
  //   '*/api/v2/playerreportdrafts/:playerReportDraftId/notes/:playerReportDraftNoteId',
  //   (req, res, ctx) => {
  //     return res(ctx.status(200), ctx.delay(), ctx.json({}));
  //   }
  // ),

  // rest.put(
  //   '*/api/v2/playerreportdrafts/:playerReportDraftId/participants/players',
  //   (req, res, ctx) => {
  //     return res(ctx.status(200), ctx.delay(), ctx.json({}));
  //   }
  // ),

  // rest.post('*/api/v2/scoutingreports', (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(), ctx.json({}));
  // }),

  // rest.get('*/api/v2/scouts/:scoutId/scoutingnotes', (req, res, ctx) => {
  //   const status = req.url.searchParams.get('status');

  //   if (status === '2') {
  //     return res(
  //       ctx.status(200),
  //       ctx.delay(),
  //       ctx.json(mockData.playerInProgressReportJSON)
  //     );
  //   } else if (status === '3') {
  //     return res(
  //       ctx.status(200),
  //       ctx.delay(),
  //       ctx.json(mockData.playerCompletedReportJSON)
  //     );
  //   }
  // }),
  // rest.get('*/api/v2/scouts/:scoutId/scoutingnotes/count', (req, res, ctx) => {
  //   const status = req.url.searchParams.get('status');

  //   if (status === '2') {
  //     return res(
  //       ctx.status(200),
  //       ctx.delay(),
  //       ctx.json(mockData.inProgressReportCountJSON)
  //     );
  //   } else if (status === '3') {
  //     return res(
  //       ctx.status(200),
  //       ctx.delay(),
  //       ctx.json(mockData.completedReportCountJSON)
  //     );
  //   }
  // }),
];
