export const authPages = {
  signIn: '/auth/signin',
  verifyRequest: '/auth/verify-request', // NOTE: A redirect for this is placed in next.config.mjs from api/auth/verify-request as well due to an issue.
  error: '/auth/error',
  signOut: '/auth/signout',
};

export const signInPageCallbackSearchParamName = 'callbackUrl';

export const signInPageURL = authPages.signIn;
