import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import * as React from 'react';

export interface ConfirmationOptions {
  variant: 'confirm' | 'alert';
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  okButtonText?: string;
  okButtonProps?: Omit<ButtonProps, 'onClick' | 'autoFocus'>;
  cancelButtonText?: string;
  cancelButtonProps?: Omit<ButtonProps, 'onClick' | 'autoFocus'>;
  circularProgressProps?: CircularProgressProps;
  showLoaderOnOk?: boolean;
}

interface Props extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onClosed: () => void;
  waitingForCloseAction: boolean;
}

const customStyles = {
  text: {
    whiteSpace: 'pre-wrap',
  },
  progress: {
    mr: 2,
  },
};

export const ConfirmationDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  onClosed,
  okButtonText,
  okButtonProps = {},
  cancelButtonText,
  cancelButtonProps = {},
  waitingForCloseAction,
  circularProgressProps,
}: Props) => (
  <Dialog open={open} onClose={!waitingForCloseAction ? onClosed : undefined}>
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>
      <DialogContentText sx={customStyles.text}>
        {description}
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      {variant === 'confirm' && (
        <>
          <Button
            disabled={waitingForCloseAction}
            onClick={onClose}
            variant="outlined"
            {...cancelButtonProps}
          >
            {cancelButtonText || 'Cancel'}
          </Button>

          <Button
            onClick={onSubmit}
            variant="primary"
            autoFocus
            disabled={waitingForCloseAction}
            {...okButtonProps}
          >
            {waitingForCloseAction && (
              <CircularProgress
                size={20}
                color="secondary"
                sx={customStyles.progress}
                {...circularProgressProps}
              />
            )}

            {okButtonText || 'OK'}
          </Button>
        </>
      )}

      {variant === 'alert' && (
        <Button onClick={onSubmit} autoFocus {...okButtonProps}>
          {okButtonText || 'OK'}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
