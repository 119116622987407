const ethnicityJSON = {
  items: [
    {
      id: 2,
      name: 'White',
    },
    {
      id: 4,
      name: 'Mixed/ Multiple Ethnic Groups',
    },
    {
      id: 1,
      name: 'Asian/ Asian British',
    },
    {
      id: 3,
      name: 'Black/ African/ Caribbean/ Black British',
    },
    {
      id: 5,
      name: 'Arab',
    },
    {
      id: 6,
      name: 'Other',
    },
    {
      id: 7,
      name: 'Prefer not to say',
    },
  ],
};

export default ethnicityJSON;
