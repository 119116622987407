const playerReportAnswersCalceyJSON = {
  homeTeamParticipantId: -1,
  awayTeamParticipantId: -2,
  startTime: '2023-04-04T10:10:10.1Z',
  localTimeZoneId: 'Asia/Colombo',
  created: '2023-04-04T09:59:44.5937806Z',
  participants: [
    {
      participantId: 1483,
      participantType: 2,
      player: {
        id: 16547,
        name: 'Tom Breaker',
        profileImage: {
          thumbnail:
            'https://dhie7xdg4ofjy.cloudfront.net/user/9f092ab3-42f5-4578-aa42-e0e8fe760941/35256220-be22-11eb-9a35-113d3a8babfe.img-thumbnail-100.png',
          mediaType: 2,
          path: 'user/9f092ab3-42f5-4578-aa42-e0e8fe760941/35256220-be22-11eb-9a35-113d3a8babfe.img',
          url: 'https://dhie7xdg4ofjy.cloudfront.net/user/9f092ab3-42f5-4578-aa42-e0e8fe760941/35256220-be22-11eb-9a35-113d3a8babfe.img?Expires=1680948787&Signature=CFdzNWDUC0BrcN~MMtygRZI-VOqutHdt8m2iQQyG~wwwZCdIt9LsYtQgfHUq99JVaLmQRvXIyaijG70dY61MAtM7QdKXQu0VjVyRaYDPuvzxIdayFVTmwnZaUnbpxxtWWmw9AD8DdEuktw8ucUJOHmjWKx52VkMei1Jl-J~kfnaYHNATTCaIl9XZ8KQS0pjaiL~YomZsPBCc2AJNHD8z2X~uChosN3LUn606-2x3LliJ~kOnABdIpMfSJrVQK3UGGRna~GMEfafLmlvK~cgv1fgG9rRY0OjWJR456sOuJKBg~3MVlv3MbRAKHP~Ly9aB07Q~vADe1Fecw9w9ACv2QA__&Key-Pair-Id=K1CE5YTDGLWCHZ',
        },
        primaryPosition: {
          id: 8,
          name: 'Left Midfield',
        },
      },
    },
    {
      participantId: 1692,
      participantType: 2,
      player: {
        id: 18335,
        name: 'Zak baig',
        profileImage: {
          thumbnail:
            'https://dhie7xdg4ofjy.cloudfront.net/user/3e2856ae-4391-4491-a77b-75ef5e058074/a4548330-840f-11eb-9ec9-e721cc26530e.img-thumbnail-100.png',
          mediaType: 2,
          path: 'user/3e2856ae-4391-4491-a77b-75ef5e058074/a4548330-840f-11eb-9ec9-e721cc26530e.img',
          url: 'https://dhie7xdg4ofjy.cloudfront.net/user/3e2856ae-4391-4491-a77b-75ef5e058074/a4548330-840f-11eb-9ec9-e721cc26530e.img?Expires=1680948787&Signature=Dm-1CYZoC7i2YzwilcPWNTicKwWo7KXWdknhyBU1x09A8PMRbMtC0m1~0D2RBQTxqJfSD--tLkmpqHuPwjCHXVyh9R2NRsJuKJ-Dw9EGIaXIwRbKnM8ovoiYOml4INN8RvdE0j5Rbn2Ab98lXdTm3UgTyPd4TAoFqpWQdobp9tbhrLke9lqsBLsMzEyVU7cwGopYi4gTfqUrK2w0vgkw-dqJ5YGzfdC16KiQGebJfYejyTNNgPMpB5v1R2lfUFLyNIvMlDdI9WKO8yfnZgUw1Gz5iY8M~K3xmgoDLw6ZYmf5-5A~nearACsm4e4WiJ0ZRBbad95PqBQzZIdUbIbyQg__&Key-Pair-Id=K1CE5YTDGLWCHZ',
        },
      },
    },
    {
      participantId: 1493,
      participantType: 2,
      player: {
        id: 18334,
        name: 'Alan Carter',
        profileImage: {
          thumbnail:
            'https://dhie7xdg4ofjy.cloudfront.net/user/3e2856ae-4391-4491-a77b-75ef5e058074/a4548330-840f-11eb-9ec9-e721cc26530e.img-thumbnail-100.png',
          mediaType: 2,
          path: 'user/3e2856ae-4391-4491-a77b-75ef5e058074/a4548330-840f-11eb-9ec9-e721cc26530e.img',
          url: 'https://dhie7xdg4ofjy.cloudfront.net/user/3e2856ae-4391-4491-a77b-75ef5e058074/a4548330-840f-11eb-9ec9-e721cc26530e.img?Expires=1680948787&Signature=Dm-1CYZoC7i2YzwilcPWNTicKwWo7KXWdknhyBU1x09A8PMRbMtC0m1~0D2RBQTxqJfSD--tLkmpqHuPwjCHXVyh9R2NRsJuKJ-Dw9EGIaXIwRbKnM8ovoiYOml4INN8RvdE0j5Rbn2Ab98lXdTm3UgTyPd4TAoFqpWQdobp9tbhrLke9lqsBLsMzEyVU7cwGopYi4gTfqUrK2w0vgkw-dqJ5YGzfdC16KiQGebJfYejyTNNgPMpB5v1R2lfUFLyNIvMlDdI9WKO8yfnZgUw1Gz5iY8M~K3xmgoDLw6ZYmf5-5A~nearACsm4e4WiJ0ZRBbad95PqBQzZIdUbIbyQg__&Key-Pair-Id=K1CE5YTDGLWCHZ',
        },
      },
    },
    {
      participantId: -1,
      participantType: 1,
      team: {
        id: 100,
        name: 'Chelsea FC',
        ageGroup: {
          id: 12,
          name: 'Under 18',
        },
      },
    },
    {
      participantId: -2,
      participantType: 1,
      team: {
        id: 200,
        name: 'Liverpool FC',
        ageGroup: {
          id: 12,
          name: 'Under 18',
        },
      },
    },
  ],
  notes: [
    {
      id: 1447,
      noteType: 1,
      title: 'asd',
      textContent: 'asd',
      startTime: '2023-04-04T10:20:10.1Z',
    },
    {
      id: 1448,
      noteType: 1,
      title: 'asdef',
      textContent: 'asdbbbbbbb',
      startTime: '2023-04-04T10:50:10.1Z',
    },
  ],
  responses: [
    {
      questionId: 1034,
      answers: [
        {
          optionId: 1096,
          value: 'Under 7',
        },
      ],
    },
    {
      questionId: 1035,
      answers: [
        {
          value: 'asd',
        },
      ],
    },
    {
      questionId: 1038,
      participantId: 1707,
      answers: [],
    },
    {
      questionId: 1039,
      participantId: 1707,
      answers: [
        {
          optionId: 1127,
          value: 'Left',
        },
      ],
    },
    {
      questionId: 1081,
      participantId: 1707,
      answers: [
        {
          value: 'TEST',
        },
      ],
    },
  ],
};

export default playerReportAnswersCalceyJSON;
