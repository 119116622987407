import { Box, Stack } from '@mui/material';
import React from 'react';

import ModalHeader from './ModalHeader';
import ScreenInfo from './ScreenInfo';

interface Props {
  onSubmit: () => void;
  url: string;
  onClose: () => void;
  isPerformanceLabPage: boolean;
}

const ModalContent = ({
  onSubmit,
  url,
  onClose,
  isPerformanceLabPage,
}: Props) => (
  <Box
    sx={(theme) => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      pointerEvents: 'auto',
      backgroundColor: theme.palette.mode === 'dark' ? '#051521' : 'white',
      backgroundClip: isPerformanceLabPage ? 'padding-box' : {},
      border: isPerformanceLabPage ? '1px solid rgba(0,0,0,.2)' : {},
      borderRadius: '5px',
      outline: 0,
    })}
  >
    <ModalHeader
      onClose={onClose}
      isPerformanceLabPage={isPerformanceLabPage}
    />

    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        flex: '1 1 auto',
        p: isPerformanceLabPage ? 4 : 0,
      }}
    >
      <Stack sx={{ flex: 1.5 }}>
        <ScreenInfo
          url={url}
          onSubmit={onSubmit}
          label="14-A"
          smallText
          deviceName="Lab_1/Front_1_A"
        />
        <ScreenInfo
          url={url}
          onSubmit={onSubmit}
          label="14-B"
          smallText
          deviceName="Lab_1/Front_1_B"
        />
        <ScreenInfo
          url={url}
          onSubmit={onSubmit}
          label="14-C"
          smallText
          deviceName="Lab_1/Front_1_C"
        />
      </Stack>

      <ScreenInfo
        url={url}
        onSubmit={onSubmit}
        label={14}
        portrait
        deviceName="Lab_1/Front_1"
      />

      <Box sx={{ flex: 8, mx: 3 }}>
        <Stack direction="row">
          <ScreenInfo
            url={url}
            onSubmit={onSubmit}
            label={13}
            deviceName="Lab_1/CF_Rear_4"
          />
          <ScreenInfo
            url={url}
            onSubmit={onSubmit}
            label={12}
            deviceName="Lab_1/CF_Rear_3"
          />
          <ScreenInfo
            url={url}
            onSubmit={onSubmit}
            label={11}
            deviceName="Lab_1/CF_Rear_2"
          />
          <ScreenInfo
            url={url}
            onSubmit={onSubmit}
            label={10}
            deviceName="Lab_1/CF_Rear_1"
          />
        </Stack>

        <Box
          sx={{
            flex: '1',
            my: 3,
            mx: 1,
            height: '75px',
            background: '#8C8C8C',
            borderRadius: 1,
          }}
        />

        <Box sx={{ display: 'flex' }}>
          <Stack sx={{ flex: 1 }}>
            <ScreenInfo
              url={url}
              onSubmit={onSubmit}
              label={1}
              deviceName="Lab_1/CF_Front_1"
            />
            <ScreenInfo
              url={url}
              onSubmit={onSubmit}
              label={2}
              deviceName="Lab_1/CF_Front_2"
            />
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <ScreenInfo
              url={url}
              onSubmit={onSubmit}
              label={3}
              deviceName="Lab_1/CF_Front_3"
            />
            <ScreenInfo
              url={url}
              onSubmit={onSubmit}
              label={4}
              deviceName="Lab_1/CF_Front_4"
            />
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <ScreenInfo
              url={url}
              onSubmit={onSubmit}
              label={5}
              deviceName="Lab_1/CF_Front_5"
            />
            <ScreenInfo
              url={url}
              onSubmit={onSubmit}
              label={6}
              deviceName="Lab_1/CF_Front_6"
            />
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <ScreenInfo
              url={url}
              onSubmit={onSubmit}
              label={7}
              deviceName="Lab_1/CF_Front_7"
            />
            <ScreenInfo
              url={url}
              onSubmit={onSubmit}
              label={8}
              deviceName="Lab_1/CF_Front_8"
            />
          </Stack>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flex: 3.6,
          height: '263px',
          margin: 'auto',
        }}
      >
        <ScreenInfo
          url={url}
          onSubmit={onSubmit}
          label={9}
          portrait
          deviceName="Lab_1/Rear_1"
        />

        <Stack sx={{ flex: 1.5 }}>
          <ScreenInfo
            url={url}
            onSubmit={onSubmit}
            label="9-A"
            smallText
            deviceName="Lab_1/Rear_1_A"
          />
          <ScreenInfo
            url={url}
            onSubmit={onSubmit}
            label="9-B"
            smallText
            deviceName="Lab_1/Rear_1_B"
          />
        </Stack>
      </Box>
    </Box>
  </Box>
);

export default ModalContent;
