const playerProfileJSON = {
  id: 10648,
  name: 'Luca Chadli',
  signedProClubId: null,
  proClubSignedType: 0,
  clubPartnershipProgramme: true,
  playerProfileWidget: {
    team: 'Scunthorpe United FC',
    teams: [
      'Chelsea FC Academy',
      'Scunthorpe United FC',
      'Burnley FC Academy',
      'AiSCOUT Academy',
    ],
    profileImage:
      'user/083dd24a-9113-415c-ab1e-2a05efc90138/9c8aa9a0-69f1-11eb-828f-798d56243b78.img',
    countryImage: 'country/f1566d65-1508-49be-b0d5-3d01df5eed02',
    nrs: 1.69785124137931,
    tps: 6.79140496551724,
  },
  playerInfoWidget: {
    gender: 1,
    age: 21,
    dob: '23/12/2001',
    height: 177.8,
    weight: 71,
    fatherHeight: 177.8,
    motherHeight: 165,
    preferredFoot: 2,
    position: 'Attacking Midfield',
    positionId: 1,
    city: 'Barrow upon Humber',
    county: 'Lincolnshire',
    nationalities: ['British', 'Algerian'],
    ethnicity: 'Mixed/ Multiple Ethnic Groups',
    canPlayFor: ['England', 'Algeria'],
  },
  trialScoresWidget: {
    scores: [
      {
        scoreName: 'Technical Rating',
        scoreValue: 9.33333333333333,
      },
      {
        scoreName: 'Speed',
        scoreValue: 0,
      },
      {
        scoreName: 'Power',
        scoreValue: 9,
      },
      {
        scoreName: 'Balance',
        scoreValue: 9,
      },
      {
        scoreName: 'Physical Rating',
        scoreValue: 5.66666666666667,
      },
    ],
  },
  positionsWidget: {
    positions: [
      {
        id: 1,
        name: 'Attacking Midfield',
      },
      {
        id: 8,
        name: 'Left Midfield',
      },
      {
        id: 9,
        name: 'Left Wing',
      },
      {
        id: 12,
        name: 'Right Midfield',
      },
      {
        id: 13,
        name: 'Right Wing',
      },
    ],
  },
  playerMapWidget: {
    postcode: 'DN197ST',
    homeLat: null,
    homeLng: null,
    trainingGroundName: 'AiSCOUT Training Ground',
    trainingGroundLat: null,
    trainingGroundLng: null,
  },
  clubTrialsWidget: {
    trials: [
      {
        title: '5-10-5 Agility With Ball',
        date: '17 March 2021',
        score: 9,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-c5cb50a0-870e-11eb-bd5e-63cd6fdb16e4.vid',
        logoRelativePath:
          'club/17/2021-10-28-Burnley-6f44f5a8-b302-444d-9899-f6c25ead8ece@500x500.img',
        clubid: 17,
      },
      {
        title: '5m Wall Pass Alternate foot',
        date: '11 March 2021',
        score: 9,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-bb4fbfd0-8281-11eb-8d62-073295787e98.vid',
        logoRelativePath:
          'club/17/2021-10-28-Burnley-6f44f5a8-b302-444d-9899-f6c25ead8ece@500x500.img',
        clubid: 17,
      },
      {
        title: '7 Cone Weave – Left foot',
        date: '04 March 2021',
        score: 10,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-acb71740-7ce4-11eb-9159-bfc5c2416031.vid',
        logoRelativePath:
          'club/17/2021-10-28-Burnley-6f44f5a8-b302-444d-9899-f6c25ead8ece@500x500.img',
        clubid: 17,
      },
      {
        title: '7 Cone Weave - Right foot',
        date: '04 March 2021',
        score: 10,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-c7535600-7ce3-11eb-9159-bfc5c2416031.vid',
        logoRelativePath:
          'club/17/2021-10-28-Burnley-6f44f5a8-b302-444d-9899-f6c25ead8ece@500x500.img',
        clubid: 17,
      },
      {
        title: 'Burnley FC - Age 14-23+',
        date: '27 February 2021',
        score: 9,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-f2733510-7916-11eb-8870-9b98cd9b11e5.vid',
        logoRelativePath:
          'club/17/2021-10-28-Burnley-6f44f5a8-b302-444d-9899-f6c25ead8ece@500x500.img',
        clubid: 17,
      },
      {
        title: '10m Sprint',
        date: '23 February 2021',
        score: 9,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-e8bd5d90-75ef-11eb-b598-273901efa737.vid',
        logoRelativePath: 'club/92/aiS_LOGO_0809-Round.png',
        clubid: 92,
      },
      {
        title: 'Lateral Rebound Jump',
        date: '23 February 2021',
        score: 8,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-43575a00-75e9-11eb-8d95-27dfc663d6eb.vid',
        logoRelativePath: 'club/92/aiS_LOGO_0809-Round.png',
        clubid: 92,
      },
      {
        title: 'Single Leg Jump - Left',
        date: '21 February 2021',
        score: 10,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-90ea2990-7446-11eb-af06-3dc303f1f213.vid',
        logoRelativePath: 'club/92/aiS_LOGO_0809-Round.png',
        clubid: 92,
      },
      {
        title: '5-10-5 Agility - Right Turn',
        date: '18 February 2021',
        score: 9,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-92d3c6f0-721e-11eb-ad3b-a7dd7abc2d77.vid',
        logoRelativePath:
          'club/17/2021-10-28-Burnley-6f44f5a8-b302-444d-9899-f6c25ead8ece@500x500.img',
        clubid: 17,
      },
      {
        title: '30 Second Press-Up',
        date: '16 February 2021',
        score: 9,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-11031680-70a2-11eb-afc1-5583d61ce254.vid',
        logoRelativePath: 'club/92/aiS_LOGO_0809-Round.png',
        clubid: 92,
      },
      {
        title: '5-10-5 Agility - Left Turn ',
        date: '16 February 2021',
        score: 9.5,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-30102510-706e-11eb-9716-419e23b916bf.vid',
        logoRelativePath:
          'club/17/2021-10-28-Burnley-6f44f5a8-b302-444d-9899-f6c25ead8ece@500x500.img',
        clubid: 17,
      },
      {
        title: '7 Cone Weave - Both Feet',
        date: '14 February 2021',
        score: 10,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-272fb220-6f0c-11eb-8180-a13d1e21bd6c.vid',
        logoRelativePath:
          'club/17/2021-10-28-Burnley-6f44f5a8-b302-444d-9899-f6c25ead8ece@500x500.img',
        clubid: 17,
      },
      {
        title: 'Single Leg Jump - Right',
        date: '09 February 2021',
        score: 9,
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/analyzed-cd05ca70-6ad1-11eb-afde-1372e93fdd45.vid',
        logoRelativePath: 'club/92/aiS_LOGO_0809-Round.png',
        clubid: 92,
      },
    ],
  },
  playerFeedWidget: {
    feed: [
      {
        date: '28 April 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/c24eba70-a833-11eb-9281-67556f2e81e2.vid',
      },
      {
        date: '28 April 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/fa3986b0-a831-11eb-9281-67556f2e81e2.vid',
      },
      {
        date: '06 April 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/fc76db40-9714-11eb-ad96-2ddefcd1e974.vid',
      },
      {
        date: '06 April 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/e7149d00-9714-11eb-ad96-2ddefcd1e974.vid',
      },
      {
        date: '06 April 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/d4743de0-9714-11eb-ad96-2ddefcd1e974.vid',
      },
      {
        date: '06 April 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/9abade10-9714-11eb-ad96-2ddefcd1e974.vid',
      },
      {
        date: '06 April 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/8736d5b0-9714-11eb-ad96-2ddefcd1e974.vid',
      },
      {
        date: '06 April 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/710bf040-9714-11eb-ad96-2ddefcd1e974.vid',
      },
      {
        date: '19 March 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/78594950-88ce-11eb-bbe8-f16a77dba458.vid',
      },
      {
        date: '19 March 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/0ecb7f30-88ce-11eb-bbe8-f16a77dba458.vid',
      },
      {
        date: '16 March 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/c244b5a0-8684-11eb-985e-8b471ff7509c.vid',
      },
      {
        date: '25 February 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/991c7d10-7767-11eb-956d-2d487e6e9e26.vid',
      },
      {
        date: '25 February 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/10fb3ed0-7767-11eb-956d-2d487e6e9e26.vid',
      },
      {
        date: '08 February 2021',
        description: 'Workouts',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/acb45940-6a3a-11eb-8546-212ae38c4d44.vid',
      },
      {
        date: '08 February 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/fa1173e0-6a39-11eb-8546-212ae38c4d44.vid',
      },
      {
        date: '08 February 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/6c4f7980-6a39-11eb-8546-212ae38c4d44.vid',
      },
      {
        date: '08 February 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/d9d6ca50-6a37-11eb-8546-212ae38c4d44.vid',
      },
      {
        date: '08 February 2021',
        description: '',
        videoUrl:
          'user/083dd24a-9113-415c-ab1e-2a05efc90138/post/c19f36e0-6a35-11eb-8546-212ae38c4d44.vid',
      },
    ],
  },
  gameClipsWidget: {
    clips: [
      {
        title: 'Burnley FC u18 v AiSCOUT',
        videoLength: '152:27',
        videoUrl:
          'https://c.veocdn.com/e89047a9-1b32-4f5b-9158-cb3c16b240db/standard/machine/7777d68b/video.mp4',
        embed: false,
      },
    ],
  },
  externalPlayerData: {
    data: [
      {
        title: 'Burnley FC u18 v AiSCOUT',
        date: '12 August 2021',
        icon: 'http://aiscout.startotype.com/sites/116/images/playermaker-icon.png',
        dataPath: 'http://aiscout.startotype.com/sites/116/pdf/playermaker.pdf',
        isExternalLink: false,
      },
      {
        title: 'Burnley FC u18 v AiSCOUT',
        date: '12 August 2021',
        icon: 'http://aiscout.startotype.com/sites/116/images/playertek-icon.png',
        dataPath: 'http://aiscout.startotype.com/sites/116/pdf/playertek.pdf',
        isExternalLink: false,
      },
    ],
  },
  trialPerformanceWidget: {
    trials: [],
  },
  notesWidget: {
    notes: [
      {
        id: 32,
        note: 'React note',
        username: 'Scout28@highstreet.net',
        userid: 3147,
        noteDate: '03 May 2023 09:45 AM',
      },
      {
        id: 41,
        note: 'Test',
        username: 'Scout28@highstreet.net',
        userid: 3147,
        noteDate: '04 May 2023 09:13 PM',
      },
      {
        id: 46,
        note: 'test',
        username: 'Scout28@highstreet.net',
        userid: 2962,
        noteDate: '19 May 2023 01:03 AM',
      },
      {
        id: 73,
        note: 'Test',
        username: 'Scout28@highstreet.net',
        userid: 2962,
        noteDate: '20 June 2023 09:36 AM',
      },
      {
        id: 78,
        note: 'tst note',
        username: 'yasas+cc@calcey.com',
        userid: 3193,
        noteDate: '21 June 2023 07:58 AM',
      },
    ],
  },
  scoutReportsWidget: {
    reports: [],
    logoRelativePath: 'club/92/aiS_LOGO_0809-Round.png',
  },
  playerContactWidget: {},
};

export default playerProfileJSON;
