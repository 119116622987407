'use client';
import * as React from 'react';

import { ConfirmationDialog, ConfirmationOptions } from './ConfirmationDialog';

interface Props {
  children: React.ReactNode;
}

interface ConfirmationServiceContextType {
  confirmation: (options: ConfirmationOptions) => Promise<boolean>;
  closeConfirmation: () => void;
}

const ConfirmationServiceContext =
  React.createContext<ConfirmationServiceContextType>({
    confirmation: Promise.reject,
    closeConfirmation: () => {},
  });

export const useConfirmation = (): ConfirmationServiceContextType =>
  React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children }: Props) => {
  const [confirmationState, setConfirmationState] =
    React.useState<ConfirmationOptions | null>(null);

  const [waitingForCloseAction, setWaitingForCloseAction] =
    React.useState<boolean>(false);

  const [open, setOpen] = React.useState<boolean>(false);

  const awaitingPromiseRef = React.useRef<{
    resolve: (confirmation: boolean) => void;
    reject: () => void;
  }>();

  const confirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    setOpen(true);
    return new Promise<boolean>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);

      setOpen(false);
    }
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true);
    }

    if (confirmationState?.showLoaderOnOk) {
      setWaitingForCloseAction(true);
    } else {
      setOpen(false);
    }
  };

  const closeConfirmation = () => {
    setOpen(false);
    setWaitingForCloseAction(false);
  };

  const handleClosed = () => {
    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={{ confirmation, closeConfirmation }}
      >
        {children}
      </ConfirmationServiceContext.Provider>

      {confirmationState && (
        <ConfirmationDialog
          open={open}
          onSubmit={handleSubmit}
          onClose={handleClose}
          onClosed={handleClosed}
          waitingForCloseAction={waitingForCloseAction}
          {...confirmationState}
        />
      )}
    </>
  );
};
