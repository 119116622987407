import * as React from 'react';

import CastModal, { CastOptions } from './CastModal';

interface Props {
  children: React.ReactNode;
}

const CastServiceContext = React.createContext<
  (options: CastOptions) => Promise<boolean>
>(Promise.reject);

export const useCast = (): ((options: CastOptions) => Promise<boolean>) =>
  React.useContext(CastServiceContext);

export const CastServiceProvider = ({ children }: Props) => {
  const [castState, setCastState] = React.useState<CastOptions | null>(null);

  const [open, setOpen] = React.useState<boolean>(false);

  const awaitingPromiseRef = React.useRef<{
    resolve: (confirmation: boolean) => void;
    reject: () => void;
  }>();

  const openCast = (options: CastOptions) => {
    setCastState(options);
    setOpen(true);
    return new Promise<boolean>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);

      setOpen(false);
    }
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true);
    }

    setOpen(false);
  };

  const handleClosed = () => {
    if (awaitingPromiseRef && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);
    }

    setCastState(null);
  };

  return (
    <>
      <CastServiceContext.Provider value={openCast}>
        {children}
      </CastServiceContext.Provider>

      {castState && (
        <CastModal
          open={open}
          onSubmit={handleSubmit}
          onClose={handleClose}
          onClosed={handleClosed}
          {...castState}
        />
      )}
    </>
  );
};
