import academiesJSON from './academiesJSON';
import ageGroupsJSON from './ageGroupsJSON';
import playerReportAnswersCalceyJSON from './CalceyEndPoints/playerReportAnswersCalceyJSON';
import {
  playerCompletedReportJSON,
  playerInProgressReportJSON,
} from './CalceyEndPoints/playerReportsCalceyJSON';
import countiesJSON from './countiesJSON';
import countriesJSON from './countriesJSON';
import ethnicityJSON from './ethnicityJSON';
import nationalitiesJSON from './nationalitiesJSON';
import playerProfileJSON from './playerProfileJSON';
import positionsCalceyJSON from './positionsCalceyJSON';
import positionsJSON from './positionsJSON';
import proClubBioDetailsJSON from './proClubBioDetailsJSON';
import signUpsJSON from './signUpsJSON';
import signUpTiersJSON from './signUpTiersJSON';
import teamSearchJSON from './teamSearchJSON';
import trialCategoriesJSON from './trailCategoriesJSON';

const mockData = {
  countriesJSON,
  nationalitiesJSON,
  countiesJSON,
  ethnicityJSON,
  signUpTiersJSON,
  proClubBioDetailsJSON,
  trialCategoriesJSON,
  academiesJSON,
  ageGroupsJSON,
  teamSearchJSON,
  playerCompletedReportJSON,
  playerInProgressReportJSON,
  playerReportAnswersCalceyJSON,
  positionsCalceyJSON,
  positionsJSON,
  signUpsJSON,
  playerProfileJSON,
};

export default mockData;
