const trialCategoriesJSON = {
  items: [
    {
      id: 4,
      title: 'Professional club VR trials',
      bannerImage: {
        thumbnail:
          'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b',
        mediaType: 2,
        path: 'club/7bebfc38-3084-475b-a273-50503eb9751b',
        url: 'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b?AWSAccessKeyId=AKIA5X5RDWWGJROVUWK7&Expires=1669632226&Signature=1f%2BZ%2FVXQ%2BawC%2BlsbRlFyzS5F96U%3D',
      },
      trialType: 4,
    },
    {
      id: 5,
      title: 'NRS trials',
      bannerImage: {
        thumbnail:
          'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b',
        mediaType: 2,
        path: 'club/7bebfc38-3084-475b-a273-50503eb9751b',
        url: 'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b?AWSAccessKeyId=AKIA5X5RDWWGJROVUWK7&Expires=1669632226&Signature=1f%2BZ%2FVXQ%2BawC%2BlsbRlFyzS5F96U%3D',
      },
      trialType: 5,
    },
    {
      id: 6,
      title: 'FA and National squad VR trials',
      bannerImage: {
        thumbnail:
          'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b',
        mediaType: 2,
        path: 'club/7bebfc38-3084-475b-a273-50503eb9751b',
        url: 'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b?AWSAccessKeyId=AKIA5X5RDWWGJROVUWK7&Expires=1669632226&Signature=1f%2BZ%2FVXQ%2BawC%2BlsbRlFyzS5F96U%3D',
      },
      trialType: 6,
    },
    {
      id: 7,
      title: 'Sponsored VR trials',
      bannerImage: {
        thumbnail:
          'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b',
        mediaType: 2,
        path: 'club/7bebfc38-3084-475b-a273-50503eb9751b',
        url: 'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b?AWSAccessKeyId=AKIA5X5RDWWGJROVUWK7&Expires=1669632226&Signature=1f%2BZ%2FVXQ%2BawC%2BlsbRlFyzS5F96U%3D',
      },
      trialType: 7,
    },
    {
      id: 8,
      title: 'Education & Academy VR trials',
      bannerImage: {
        thumbnail:
          'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b',
        mediaType: 2,
        path: 'club/7bebfc38-3084-475b-a273-50503eb9751b',
        url: 'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b?AWSAccessKeyId=AKIA5X5RDWWGJROVUWK7&Expires=1669632226&Signature=1f%2BZ%2FVXQ%2BawC%2BlsbRlFyzS5F96U%3D',
      },
      trialType: 8,
    },
    {
      id: 9,
      title: 'Affiliate VR trial',
      bannerImage: {
        thumbnail:
          'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b',
        mediaType: 2,
        path: 'club/7bebfc38-3084-475b-a273-50503eb9751b',
        url: 'https://spotme-daily.s3.amazonaws.com/club/7bebfc38-3084-475b-a273-50503eb9751b?AWSAccessKeyId=AKIA5X5RDWWGJROVUWK7&Expires=1669632226&Signature=1f%2BZ%2FVXQ%2BawC%2BlsbRlFyzS5F96U%3D',
      },
      trialType: 9,
    },
    {
      id: 10,
      title: 'Major League Soccer League',
      bannerImage: {
        thumbnail:
          'https://spotme-daily.s3.amazonaws.com/club/b702a032-0cff-4562-8ef5-728116b80118',
        mediaType: 2,
        path: 'club/b702a032-0cff-4562-8ef5-728116b80118',
        url: 'https://spotme-daily.s3.amazonaws.com/club/b702a032-0cff-4562-8ef5-728116b80118?AWSAccessKeyId=AKIA5X5RDWWGJROVUWK7&Expires=1669632226&Signature=0ZhkK%2Fzfcvtkaq8x8z9fzt6RzbE%3D',
      },
      trialType: 10,
    },
    {
      id: 11,
      title: 'Academy Standard Trials',
      trialType: 11,
    },
    {
      id: 12,
      title: 'Academy Analysis Trials',
      trialType: 12,
    },
  ],
};

export default trialCategoriesJSON;
