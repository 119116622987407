const positionsCalceyJSON = {
  items: [
    {
      id: 1,
      name: 'Attacking Midfield',
      abbreviation: 'ATT',
    },
    {
      id: 2,
      name: 'Central Midfield',
      abbreviation: 'CEN',
    },
    {
      id: 3,
      name: 'Centre Back',
      abbreviation: 'CEN',
    },
    {
      id: 4,
      name: 'Centre Forward',
      abbreviation: 'CEN',
    },
    {
      id: 5,
      name: 'Defensive Midfield',
      abbreviation: 'DEF',
    },
    {
      id: 6,
      name: 'Goalkeeper',
      abbreviation: 'GOA',
    },
    {
      id: 7,
      name: 'Left Back',
      abbreviation: 'LEF',
    },
    {
      id: 8,
      name: 'Left Midfield',
      abbreviation: 'LEF',
    },
    {
      id: 9,
      name: 'Left Wing',
      abbreviation: 'LEF',
    },
    {
      id: 10,
      name: 'Left Wing Back',
      abbreviation: 'LEF',
    },
    {
      id: 11,
      name: 'Right Back',
      abbreviation: 'RIG',
    },
    {
      id: 12,
      name: 'Right Midfield',
      abbreviation: 'RIG',
    },
    {
      id: 13,
      name: 'Right Wing',
      abbreviation: 'RIG',
    },
    {
      id: 14,
      name: 'Right Wing Back',
      abbreviation: 'RIG',
    },
    {
      id: 15,
      name: 'Striker',
      abbreviation: 'STR',
    },
    {
      id: 16,
      name: 'Sweeper',
      abbreviation: 'SWE',
    },
    {
      id: 17,
      name: 'Wing Back',
      abbreviation: 'WIN',
    },
    {
      id: 18,
      name: 'Referee',
      abbreviation: 'REF',
    },
  ],
};

export default positionsCalceyJSON;
