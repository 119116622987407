import { createEnv } from '@t3-oss/env-nextjs';
import { vercel } from '@t3-oss/env-nextjs/presets';
import ansis from 'ansis';
import { z } from 'zod';

export const env = createEnv({
  extends: [vercel()],
  server: {
    /**
     * This is the NODE Env set by the framework.
     */
    NODE_ENV: z
      .union(
        [z.literal('production'), z.literal('development'), z.literal('test')],
        {
          required_error:
            'NODE_ENV is required and should be either "production", "test" or "development"',
        }
      )
      .optional(),
    /**
     * This is the next.js basePath used in the next.js config and utilised as
     * the base for all routing. This will be set to the full path when the app
     * is hosted at a non-root location (For example /cc)
     *
     * @link https://nextjs.org/docs/api-reference/next.config.js/basepath
     *
     * @example /cc
     */
    BASE_PATH: z.string(),
    /**
     * This is the Connection string used for the Control Center Database
     */
    DATABASE_URL: z.string(),
    /**
     * This is the Connection string used for the Calcey Database
     */

    DATABASE_URL_CALCEY_DB: z.string(),

    /**
     * This is the Connection string used for the New App Database
     */
    DATABASE_URL_APP: z.string().optional(), // TODO: Remove optional() once CI/CD Deployment in place.

    /**
     * This is the Luzmo API Key
     */
    LUZMO_API_KEY: z.string(),
    /**
     * This is the Luzmo API Token
     */
    LUZMO_API_TOKEN: z.string(),
    /**
     * This is admin scout password for admin Calcey API calls
     */
    ADMIN_SCOUT_PASSWORD: z.string(),
    /**
     * This is admin scout username for admin Calcey API calls
     */
    ADMIN_SCOUT_USERNAME: z.string(),
    /**
     * This is api key to send emails via sendgrid
     */
    SENDGRID_API_KEY: z.string(),
    /**
     * This is slack webhook endpoint to notify errors
     */
    SLACK_ERROR_LOG_NOTIFICATION_WEBHOOK: z.string().url(),
    /**
     * The name of the project. This is used when speaking about the project in
     * emails and as the default value for other ID's. It should be a human
     * readable string but not have any crazy characters in. Spaces are OK and
     * will be replaced in ID's with -'s.
     */
    PROJECT_NAME: z.string(),
    /**
     * The name to use for the Application when registering endpoints with Inngest.
     */
    INNGEST_APP_NAME: z
      .string()
      .default(
        process.env.PROJECT_NAME?.replaceAll(' ', '-').toLowerCase() ?? 'App'
      ),
    /**
     * The AWS Access Key used to access Amazon SES
     */
    AWS_SES_ACCESS_KEY_ID:
      process.env.NODE_ENV === 'production'
        ? z.string()
        : z.string().optional(),
    /**
     * The AWS Secret Access Key used to access Amazon SES
     */
    AWS_SES_SECRET_ACCESS_KEY:
      process.env.NODE_ENV === 'production'
        ? z.string()
        : z.string().optional(),
    /**
     * The AWS Region to use for Amazon SES
     */
    AWS_SES_REGION:
      process.env.NODE_ENV === 'production'
        ? z.string()
        : z.string().optional(),
    /**
     * The address to make emails come from.
     */
    EMAIL_FROM: z.string(),

    /**
     * The secret used for encrypting JWT's etc.
     *
     * generate using `npx auth secret`
     */
    AUTH_SECRET: z.string({
      required_error:
        ' AUTH_SECRET is required for casting etc. generate using `npx auth secret`',
    }),

    /**
     * The base URL to use for the Auth API's.
     *
     * NOTE: The Auth API Paths include the paths like `/api/auth` etc so this
     * should just be the base URL without a trailing /.
     *
     * @example https://controlcenter.ai.io
     */
    AUTH_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set AUTH_URL
      // Since NextAuth.js automatically uses the VERCEL_URL if present.
      (str) => str ?? process.env.VERCEL_URL,
      // VERCEL_URL doesn't include `https` so it cant be validated as a URL
      process.env.VERCEL ? z.string() : z.string().url()
    ),
    /**
     * The name to show as the From of emails
     */
    EMAIL_FROM_NAME: z.string().default(process.env.PROJECT_NAME ?? 'App'),
    REFLEXION_API_KEY: z.string(),
    REFLEXION_BASE_URL: z.string().url(),
    HONEYCOMB_API_KEY: z.string(),
    OTEL_SERVICE_NAME: z.string(),
    PORT: z.string().optional(),
  },
  client: {
    /**
     * This is the absolute URL to the S3 Bucket used by the Calcey AiSCOUT App.
     * This is the based to use for assets such as profile images returned from
     * the Calcey APIs.
     *
     * @example https://aiscout-staging.s3.amazonaws.com
     */
    NEXT_PUBLIC_CALCEY_S3_BUCKET_URL: z.string(),
    /**
     * This is the public API key for the Google Maps API.
     * It's locked to the domains of the app.
     */
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string(),
    /**
     * Production Base URL
     */
    NEXT_PUBLIC_PRODUCTION_BASE_URL: z.string().url({ message: 'Invalid url' }),
    /**
     * Environment we are running on
     */
    NEXT_PUBLIC_ENVIRONMENT: z.union([
      z.literal('production'),
      z.literal('staging'),
    ]),

    NEXT_PUBLIC_SLACK_TRIALIST_NOTIFICATION_WEBHOOK: z
      .string()
      .url({ message: 'Invalid url' }),

    NEXT_PUBLIC_REFLEXION_IFRAME_BASE_URL: z.string().url(),
    NEXT_PUBLIC_STARTOTYPE_API_BASE_URL: z
      .string()
      .url({ message: 'Invalid url' }),
    /**
     * This is the absolute URL to use as the base when making API Calls to Calcey
     * and will be set to the correct value for the environment (prod / Staging)
     *
     * @example http://stage.aiscout.io/api/v2
     */
    NEXT_PUBLIC_CALCEY_API_BASE_URL: z.string().url({ message: 'Invalid url' }),
    /**
     * This is the absolute URL to use as the base when making API Calls to Calcey
     * and will be set to the correct value for the environment (prod / Staging)
     * This is the one to use with Orval!
     *
     * @example http://stage.aiscout.io
     */
    NEXT_PUBLIC_CALCEY_API_BASE_URL_RAW: z
      .string()
      .url({ message: 'Invalid url' }),
    /**
     * This is the base URL to use for Assets and links to delphi pages.
     * It will be set to the correct value for each environment. (prod / Staging)
     */
    NEXT_PUBLIC_BASE_PATH_AISCOUT: z.string().url({ message: 'Invalid url' }),
    /**
     * This is the absolute URL to use as the base when making API Calls to do
     * Screen Casting functions and will be correct for production or staging.
     *
     * @example https://pl.aiscout.io
     */
    NEXT_PUBLIC_SCREEN_API_BASE_URL: z.string().url({ message: 'Invalid url' }),
    NEXT_PUBLIC_NODE_ENV: z.union(
      [z.literal('production'), z.literal('development'), z.literal('test')],
      {
        required_error:
          'NODE_ENV is required and should be either "production", "test" or "development"',
      }
    ),
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_CALCEY_S3_BUCKET_URL:
      process.env.NEXT_PUBLIC_CALCEY_S3_BUCKET_URL,
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY:
      process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    NEXT_PUBLIC_PRODUCTION_BASE_URL:
      process.env.NEXT_PUBLIC_PRODUCTION_BASE_URL,
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    NEXT_PUBLIC_SLACK_TRIALIST_NOTIFICATION_WEBHOOK:
      process.env.NEXT_PUBLIC_SLACK_TRIALIST_NOTIFICATION_WEBHOOK,
    NEXT_PUBLIC_REFLEXION_IFRAME_BASE_URL:
      process.env.NEXT_PUBLIC_REFLEXION_IFRAME_BASE_URL,
    NEXT_PUBLIC_STARTOTYPE_API_BASE_URL:
      process.env.NEXT_PUBLIC_STARTOTYPE_API_BASE_URL,
    NEXT_PUBLIC_CALCEY_API_BASE_URL:
      process.env.NEXT_PUBLIC_CALCEY_API_BASE_URL,
    NEXT_PUBLIC_CALCEY_API_BASE_URL_RAW:
      process.env.NEXT_PUBLIC_CALCEY_API_BASE_URL_RAW,
    NEXT_PUBLIC_BASE_PATH_AISCOUT: process.env.NEXT_PUBLIC_BASE_PATH_AISCOUT,
    NEXT_PUBLIC_SCREEN_API_BASE_URL:
      process.env.NEXT_PUBLIC_SCREEN_API_BASE_URL,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
  },
  onValidationError: (error) => {
    // Need to check the error in SSR and Pre Rendering is fixed by removing the process.exit...
    console.error(
      ansis.red('error -'),
      '❌ Invalid environment variables:',
      error.flatten().fieldErrors
    );
  },
  onInvalidAccess: (variable) => {
    // Need to check the error in SSR and Pre Rendering is fixed by removing the process.exit...
    console.error(
      ansis.red('error -'),
      `❌ Attempted to access a server-side environment variable ${variable} on the client`
    );
  },
  skipValidation: process.env.SKIP_ENV_VALIDATION === 'true',
});
