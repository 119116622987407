import { api } from './trpc/pages';

export { type SSRContext } from './trpc/pages';
export {
  type InferTRPCInputTypes,
  type InferTRPCOutputTypes,
  type RouterInputs,
  type RouterOutputs,
} from 'helpers/trpc/shared';

/**
 * The object to be able to access all trpc API functions.
 *
 * @deprecated Use the import of `api` for the right context instead as this is
 * semantically a lot easier to understand. In the case of use from the pages
 * router `helpers/trpc/pages`
 */
export const trpc = api;
