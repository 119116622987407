'use client';
import { Box, styled } from '@mui/material';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';

interface Props {
  snackbarKey: SnackbarKey;
}

const CloseSnackbarAction = ({ snackbarKey }: Props) => {
  const { closeSnackbar } = useSnackbar();

  const snackbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = () => closeSnackbar(snackbarKey);

    const snackbarParent = snackbarRef.current?.parentElement?.parentElement;

    if (snackbarParent && snackbarParent.className.includes('variantError')) {
      const snackBarSvgIcon = snackbarParent.childNodes[0]?.childNodes[0];
      snackBarSvgIcon?.addEventListener('click', handleClick);

      return () => snackBarSvgIcon?.removeEventListener('click', handleClick);
    }
  }, [closeSnackbar, snackbarKey]);

  return <Box ref={snackbarRef} />;
};

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    border-radius: 24px;
  }
`;

export const CustomSnackbarProvider = ({
  children,
  ...rest
}: React.ComponentProps<typeof StyledSnackbarProvider>) => {
  return (
    <StyledSnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      action={(key) => <CloseSnackbarAction snackbarKey={key} />}
      {...rest}
    >
      {children}
    </StyledSnackbarProvider>
  );
};
