// import '../styles/global.css';

import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { Analytics } from '@vercel/analytics/react';
import { env } from 'env.mjs';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextIntlClientProvider } from 'next-intl';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import createEmotionCache from 'components/MUI/createEmotionCache';
import { MUIThemeProvider } from 'components/MUI/MUIThemeProvider';
import { CastServiceProvider } from 'components/shared/CastService';
import { ConfirmationServiceProvider } from 'components/shared/ConfirmationService';
import { CustomSnackbarProvider } from 'components/shared/CustomSnackbarProvider';
import ErrorFallback from 'components/shared/ErrorFallback';
import { ReactQueryDevToolsClientComponent } from 'components/shared/ReactQueryDevTools.client';
import { RecoilProvider } from 'components/shared/RecoilProvider';
import { trpc } from 'helpers/trpc';

const clientSideEmotionCache = createEmotionCache();
const clientSide = typeof window !== 'undefined';

// Add the MSW mock server once on the client side in development.
if (clientSide && env.NEXT_PUBLIC_NODE_ENV === 'development') {
  const { worker } = require('mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
  console.info('🔶 Mock server running!');
}

interface Props extends AppProps {
  emotionCache?: EmotionCache;
  pageProps: {
    messages: Record<string, string>;
  };
}

function App(props: Props) {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  const router = useRouter();

  return (
    <>
      <NextIntlClientProvider
        locale={router.locale}
        messages={pageProps.messages}
      >
        <RecoilProvider>
          <CacheProvider value={emotionCache}>
            <MUIThemeProvider>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ReactQueryDevToolsClientComponent />
                <Head>
                  <title>ai.io | Control Centre</title>
                </Head>

                <CustomSnackbarProvider>
                  <ConfirmationServiceProvider>
                    <CastServiceProvider>
                      <CssBaseline />

                      <Component {...pageProps} />
                    </CastServiceProvider>
                  </ConfirmationServiceProvider>
                </CustomSnackbarProvider>
              </ErrorBoundary>
            </MUIThemeProvider>
          </CacheProvider>
        </RecoilProvider>
      </NextIntlClientProvider>
      <Analytics />
    </>
  );
}

export default trpc.withTRPC(App);
