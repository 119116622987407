const teamSearchJSON = {
  items: [
    {
      id: 100,
      name: 'Chelsea FC',
      division: { id: 1, name: 'one', league: { id: 1, name: 'EPL' } },
      ageGroup: { id: 1, name: 'under 12' },
      logo: { mediaType: 0, path: '', url: '' },
    },
    {
      id: 200,
      name: 'Liverpool FC',
      division: { id: 2, name: 'two', league: { id: 2, name: 'EPL TWO' } },
      ageGroup: { id: 2, name: 'under 14' },
      logo: { mediaType: 0, path: '', url: '' },
    },
    {
      id: 300,
      name: 'Manchester City FC',
      division: {
        id: 3,
        name: 'three',
        league: { id: 3, name: 'EPL THREE' },
      },
      ageGroup: { id: 1, name: 'under 12' },
      logo: { mediaType: 0, path: '', url: '' },
    },
    {
      id: 400,
      name: 'Manchester United FC',
      division: { id: 2, name: 'two', league: { id: 2, name: 'EPL TWO' } },
      ageGroup: { id: 2, name: 'under 14' },
      logo: { mediaType: 0, path: '', url: '' },
    },
    {
      id: 500,
      name: 'Arsenal FC',
      division: { id: 1, name: 'one', league: { id: 1, name: 'EPL' } },
      ageGroup: { id: 2, name: 'under 14' },
      logo: { mediaType: 0, path: '', url: '' },
    },
  ],
};

export default teamSearchJSON;
