import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';

import { brandonFontFamily } from 'components/MUI/typography';

interface Props {
  onClose: () => void;
  isPerformanceLabPage: boolean;
}

const ModalHeader = ({ onClose, isPerformanceLabPage }: Props) => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      p: isPerformanceLabPage ? 4 : 0,
      border: `${theme.palette.mode === 'dark' ? 'none' : ''}`,
      borderTopLeftRadius: 1,
      borderTopRightRadius: 1,
    })}
  >
    {isPerformanceLabPage ? (
      <Typography
        fontSize={20}
        textTransform="uppercase"
        letterSpacing={0}
        marginBottom="-6px"
        fontFamily={brandonFontFamily}
        color={(theme) => (theme.palette.mode === 'dark' ? 'white' : 'black')}
      >
        Select a screen
      </Typography>
    ) : (
      <DialogTitle sx={{ p: 0 }}>Select a screen</DialogTitle>
    )}
    <IconButton onClick={onClose}>
      <CloseRoundedIcon />
    </IconButton>
  </Box>
);

export default ModalHeader;
