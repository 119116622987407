import SidebarMenuJSON from 'components/shared/Sidebar/SidebarMenuJSON';

export const getPerformanceLabPagePaths = () => {
  const sidebarPagePaths = SidebarMenuJSON.find(
    (menu) => menu.title === 'Performance Lab'
  )!.submenu.map((submenu) => submenu.link);

  const nestedPages = ['/performancelabreport', '/performancechartboard'];

  return [...nestedPages, ...sidebarPagePaths];
};
