import { useRouter } from 'next/router';
import { RecoilRoot } from 'recoil';
import { RecoilURLSyncJSON } from 'recoil-sync';

const clientSide = typeof window !== 'undefined';

export const RecoilProvider = ({
  children,
  RecoilURLSyncJSONProps,
  ...rest
}: React.ComponentProps<typeof RecoilRoot> & {
  RecoilURLSyncJSONProps?: Partial<
    React.ComponentProps<typeof RecoilURLSyncJSON>
  >;
}) => {
  const router = useRouter();
  return (
    <RecoilRoot {...rest}>
      <RecoilURLSyncJSON
        browserInterface={{
          getURL: () => {
            if (clientSide) {
              return window.location.toString();
            } else {
              // TODO: When we have a server implement the URL from the server
              // req object. At the moment we are only in the browser so not
              // needed.
              return `http://replaceme.com`;
            }
          },
          // Make sure the event listner handles `router.push` as well as real
          // changes.
          listenChangeURL: (handleUpdate) => {
            if (clientSide) {
              window.addEventListener('popstate', handleUpdate);
              router.events.on('routeChangeComplete', handleUpdate);
              // Clear up after ourselves
            }

            return () => {
              window.removeEventListener('popstate', handleUpdate);
              router.events.off('routeChangeComplete', handleUpdate);
            };
          },
        }}
        location={{ part: 'queryParams' }}
        {...RecoilURLSyncJSONProps}
      >
        {children}
      </RecoilURLSyncJSON>
    </RecoilRoot>
  );
};
