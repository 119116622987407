const academiesJSON = {
  items: [
    {
      id: 16,
      name: 'Chelsea',
      caption: 'Watch, Learn, Progress',
      isUnlocked: true,
      hasSubscribed: true,
      subscriptionType: 1,
    },
    {
      id: 16,
      name: 'Man City',
      caption: 'Pride in battle',
      isUnlocked: true,
      hasSubscribed: true,
      subscriptionType: 1,
    },
    {
      id: 16,
      name: 'Liverpool',
      caption: "You'll Never Walk Alone",
      isUnlocked: true,
      hasSubscribed: true,
      subscriptionType: 1,
    },
  ],
};

export default academiesJSON;
