const ageGroupsJSON = {
  items: [
    {
      id: 54,
      name: 'Under 4',
      age: 4,
    },
    {
      id: 55,
      name: 'Under 5',
      age: 5,
    },
    {
      id: 56,
      name: 'Under 6',
      age: 6,
    },
    {
      id: 1,
      name: 'Under 7',
      age: 7,
    },
    {
      id: 2,
      name: 'Under 8',
      age: 8,
    },
    {
      id: 3,
      name: 'Under 9',
      age: 9,
    },
    {
      id: 4,
      name: 'Under 10 ',
      age: 10,
    },
    {
      id: 5,
      name: 'Under 11',
      age: 11,
    },
    {
      id: 6,
      name: 'Under 12',
      age: 12,
    },
    {
      id: 7,
      name: 'Under 13',
      age: 13,
    },
    {
      id: 8,
      name: 'Under 14',
      age: 14,
    },
    {
      id: 9,
      name: 'Under 15',
      age: 15,
    },
    {
      id: 10,
      name: 'Under 16',
      age: 16,
    },
    {
      id: 11,
      name: 'Under 17',
      age: 17,
    },
    {
      id: 12,
      name: 'Under 18',
      age: 18,
    },
    {
      id: 13,
      name: 'Under 19',
      age: 19,
    },
    {
      id: 14,
      name: 'Under 20',
      age: 20,
    },
    {
      id: 15,
      name: 'Under 21',
      age: 21,
    },
    {
      id: 16,
      name: 'Under 22',
      age: 22,
    },
    {
      id: 17,
      name: 'Under 23',
      age: 23,
    },
    {
      id: 18,
      name: 'Adult',
      age: 40,
    },
  ],
};

export default ageGroupsJSON;
