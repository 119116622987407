const signUpsJSON = {
  signUps: [
    {
      date: '2022-02-02',
      playersCount: 3,
    },
    {
      date: '2022-02-07',
      playersCount: 1,
    },
    {
      date: '2022-02-08',
      playersCount: 1,
    },
    {
      date: '2022-02-10',
      playersCount: 1,
    },
    {
      date: '2022-02-15',
      playersCount: 1,
    },
    {
      date: '2022-02-17',
      playersCount: 2,
    },
  ],
};

export default signUpsJSON;
