const nationalitiesJSON = {
  items: [
    {
      id: 1,
      name: 'British',
    },
    {
      id: 2,
      name: 'German',
    },
    {
      id: 3,
      name: 'Irish',
    },
    {
      id: 4,
      name: 'Scottish',
    },
    {
      id: 5,
      name: 'French',
    },
    {
      id: 6,
      name: 'Afghan',
    },
    {
      id: 7,
      name: 'Albanian',
    },
    {
      id: 8,
      name: 'Algerian',
    },
    {
      id: 9,
      name: 'American',
    },
    {
      id: 10,
      name: 'Andorran',
    },
    {
      id: 11,
      name: 'Angolan',
    },
    {
      id: 12,
      name: 'Antiguans',
    },
    {
      id: 13,
      name: 'Argentinean',
    },
    {
      id: 14,
      name: 'Armenian',
    },
    {
      id: 15,
      name: 'Australian',
    },
    {
      id: 16,
      name: 'Austrian',
    },
    {
      id: 17,
      name: 'Azerbaijani',
    },
    {
      id: 18,
      name: 'Bahamian',
    },
    {
      id: 19,
      name: 'Bahraini',
    },
    {
      id: 20,
      name: 'Bangladeshi',
    },
    {
      id: 21,
      name: 'Barbadian',
    },
    {
      id: 22,
      name: 'Barbudans',
    },
    {
      id: 23,
      name: 'Batswana',
    },
    {
      id: 24,
      name: 'Belarusian',
    },
    {
      id: 25,
      name: 'Belgian',
    },
    {
      id: 26,
      name: 'Belizean',
    },
    {
      id: 27,
      name: 'Beninese',
    },
    {
      id: 28,
      name: 'Bhutanese',
    },
    {
      id: 29,
      name: 'Bolivian',
    },
    {
      id: 30,
      name: 'Bosnian',
    },
    {
      id: 31,
      name: 'Brazilian',
    },
    {
      id: 32,
      name: 'Bruneian',
    },
    {
      id: 33,
      name: 'Bulgarian',
    },
    {
      id: 34,
      name: 'Burkinabe',
    },
    {
      id: 35,
      name: 'Burmese',
    },
    {
      id: 36,
      name: 'Burundian',
    },
    {
      id: 37,
      name: 'Cambodian',
    },
    {
      id: 38,
      name: 'Cameroonian',
    },
    {
      id: 39,
      name: 'Canadian',
    },
    {
      id: 40,
      name: 'Cape Verdean',
    },
    {
      id: 41,
      name: 'Central African',
    },
    {
      id: 42,
      name: 'Chadian',
    },
    {
      id: 43,
      name: 'Chilean',
    },
    {
      id: 44,
      name: 'Chinese',
    },
    {
      id: 45,
      name: 'Colombian',
    },
    {
      id: 46,
      name: 'Comoran',
    },
    {
      id: 47,
      name: 'Congolese',
    },
    {
      id: 48,
      name: 'Costa Rican',
    },
    {
      id: 49,
      name: 'Croatian',
    },
    {
      id: 50,
      name: 'Cuban',
    },
    {
      id: 51,
      name: 'Cypriot',
    },
    {
      id: 52,
      name: 'Czech',
    },
    {
      id: 53,
      name: 'Danish',
    },
    {
      id: 54,
      name: 'Djibouti',
    },
    {
      id: 55,
      name: 'Dominican',
    },
    {
      id: 56,
      name: 'Dutch',
    },
    {
      id: 57,
      name: 'East Timorese',
    },
    {
      id: 58,
      name: 'Ecuadorean',
    },
    {
      id: 59,
      name: 'Egyptian',
    },
    {
      id: 60,
      name: 'Emirian',
    },
    {
      id: 61,
      name: 'Equatorial Guinean',
    },
    {
      id: 62,
      name: 'Eritrean',
    },
    {
      id: 63,
      name: 'Estonian',
    },
    {
      id: 64,
      name: 'Ethiopian',
    },
    {
      id: 65,
      name: 'Fijian',
    },
    {
      id: 66,
      name: 'Filipino',
    },
    {
      id: 67,
      name: 'Finnish',
    },
    {
      id: 68,
      name: 'Gabonese',
    },
    {
      id: 69,
      name: 'Gambian',
    },
    {
      id: 70,
      name: 'Georgian',
    },
    {
      id: 71,
      name: 'Ghanaian',
    },
    {
      id: 72,
      name: 'Greek',
    },
    {
      id: 73,
      name: 'Grenadian',
    },
    {
      id: 74,
      name: 'Guatemalan',
    },
    {
      id: 75,
      name: 'Guinea-Bissauan',
    },
    {
      id: 76,
      name: 'Guinean',
    },
    {
      id: 77,
      name: 'Guyanese',
    },
    {
      id: 78,
      name: 'Haitian',
    },
    {
      id: 79,
      name: 'Herzegovinian',
    },
    {
      id: 80,
      name: 'Honduran',
    },
    {
      id: 81,
      name: 'Hungarian',
    },
    {
      id: 82,
      name: 'I-Kiribati',
    },
    {
      id: 83,
      name: 'Icelander',
    },
    {
      id: 84,
      name: 'Indian',
    },
    {
      id: 85,
      name: 'Indonesian',
    },
    {
      id: 86,
      name: 'Iranian',
    },
    {
      id: 87,
      name: 'Iraqi',
    },
    {
      id: 88,
      name: 'Israeli',
    },
    {
      id: 89,
      name: 'Italian',
    },
    {
      id: 90,
      name: 'Ivorian',
    },
    {
      id: 91,
      name: 'Jamaican',
    },
    {
      id: 92,
      name: 'Japanese',
    },
    {
      id: 93,
      name: 'Jordanian',
    },
    {
      id: 94,
      name: 'Kazakhstani',
    },
    {
      id: 95,
      name: 'Kenyan',
    },
    {
      id: 96,
      name: 'Kittian and Nevisian',
    },
    {
      id: 97,
      name: 'Kuwaiti',
    },
    {
      id: 98,
      name: 'Kyrgyz',
    },
    {
      id: 99,
      name: 'Laotian',
    },
    {
      id: 100,
      name: 'Latvian',
    },
    {
      id: 101,
      name: 'Lebanese',
    },
    {
      id: 102,
      name: 'Liberian',
    },
    {
      id: 103,
      name: 'Libyan',
    },
    {
      id: 104,
      name: 'Liechtensteiner',
    },
    {
      id: 105,
      name: 'Lithuanian',
    },
    {
      id: 106,
      name: 'Luxembourger',
    },
    {
      id: 107,
      name: 'Macedonian',
    },
    {
      id: 108,
      name: 'Malagasy',
    },
    {
      id: 109,
      name: 'Malawian',
    },
    {
      id: 110,
      name: 'Malaysian',
    },
    {
      id: 111,
      name: 'Maldivian',
    },
    {
      id: 112,
      name: 'Malian',
    },
    {
      id: 113,
      name: 'Maltese',
    },
    {
      id: 114,
      name: 'Marshallese',
    },
    {
      id: 115,
      name: 'Mauritanian',
    },
    {
      id: 116,
      name: 'Mauritian',
    },
    {
      id: 117,
      name: 'Mexican',
    },
    {
      id: 118,
      name: 'Micronesian',
    },
    {
      id: 119,
      name: 'Moldovan',
    },
    {
      id: 120,
      name: 'Monacan',
    },
    {
      id: 121,
      name: 'Mongolian',
    },
    {
      id: 122,
      name: 'Moroccan',
    },
    {
      id: 123,
      name: 'Mosotho',
    },
    {
      id: 124,
      name: 'Motswana',
    },
    {
      id: 125,
      name: 'Mozambican',
    },
    {
      id: 126,
      name: 'Namibian',
    },
    {
      id: 127,
      name: 'Nauruan',
    },
    {
      id: 128,
      name: 'Nepalese',
    },
    {
      id: 129,
      name: 'New Zealander',
    },
    {
      id: 130,
      name: 'Ni-Vanuatu',
    },
    {
      id: 131,
      name: 'Nicaraguan',
    },
    {
      id: 132,
      name: 'Nigerian',
    },
    {
      id: 133,
      name: 'Nigerien',
    },
    {
      id: 134,
      name: 'North Korean',
    },
    {
      id: 135,
      name: 'Northern Irish',
    },
    {
      id: 136,
      name: 'Norwegian',
    },
    {
      id: 137,
      name: 'Omani',
    },
    {
      id: 138,
      name: 'Pakistani',
    },
    {
      id: 139,
      name: 'Palauan',
    },
    {
      id: 140,
      name: 'Panamanian',
    },
    {
      id: 141,
      name: 'Papua New Guinean',
    },
    {
      id: 142,
      name: 'Paraguayan',
    },
    {
      id: 143,
      name: 'Peruvian',
    },
    {
      id: 144,
      name: 'Polish',
    },
    {
      id: 145,
      name: 'Portuguese',
    },
    {
      id: 146,
      name: 'Qatari',
    },
    {
      id: 147,
      name: 'Romanian',
    },
    {
      id: 148,
      name: 'Russian',
    },
    {
      id: 149,
      name: 'Rwandan',
    },
    {
      id: 150,
      name: 'Saint Lucian',
    },
    {
      id: 151,
      name: 'Salvadoran',
    },
    {
      id: 152,
      name: 'Samoan',
    },
    {
      id: 153,
      name: 'San Marinese',
    },
    {
      id: 154,
      name: 'Sao Tomean',
    },
    {
      id: 155,
      name: 'Saudi',
    },
    {
      id: 156,
      name: 'Senegalese',
    },
    {
      id: 157,
      name: 'Serbian',
    },
    {
      id: 158,
      name: 'Seychellois',
    },
    {
      id: 159,
      name: 'Sierra Leonean',
    },
    {
      id: 160,
      name: 'Singaporean',
    },
    {
      id: 161,
      name: 'Slovakian',
    },
    {
      id: 162,
      name: 'Slovenian',
    },
    {
      id: 163,
      name: 'Solomon Islander',
    },
    {
      id: 164,
      name: 'Somali',
    },
    {
      id: 165,
      name: 'South African',
    },
    {
      id: 166,
      name: 'South Korean',
    },
    {
      id: 167,
      name: 'Spanish',
    },
    {
      id: 168,
      name: 'Sri Lankan',
    },
    {
      id: 169,
      name: 'Sudanese',
    },
    {
      id: 170,
      name: 'Surinamer',
    },
    {
      id: 171,
      name: 'Swazi',
    },
    {
      id: 172,
      name: 'Swedish',
    },
    {
      id: 173,
      name: 'Swiss',
    },
    {
      id: 174,
      name: 'Syrian',
    },
    {
      id: 175,
      name: 'Taiwanese',
    },
    {
      id: 176,
      name: 'Tajik',
    },
    {
      id: 177,
      name: 'Tanzanian',
    },
    {
      id: 178,
      name: 'Thai',
    },
    {
      id: 179,
      name: 'Togolese',
    },
    {
      id: 180,
      name: 'Tongan',
    },
    {
      id: 181,
      name: 'Trinidadian or Tobagonian',
    },
    {
      id: 182,
      name: 'Tunisian',
    },
    {
      id: 183,
      name: 'Turkish',
    },
    {
      id: 184,
      name: 'Tuvaluan',
    },
    {
      id: 185,
      name: 'Ugandan',
    },
    {
      id: 186,
      name: 'Ukrainian',
    },
    {
      id: 187,
      name: 'Uruguayan',
    },
    {
      id: 188,
      name: 'Uzbekistani',
    },
    {
      id: 189,
      name: 'Venezuelan',
    },
    {
      id: 190,
      name: 'Vietnamese',
    },
    {
      id: 191,
      name: 'Welsh',
    },
    {
      id: 192,
      name: 'Yemenite',
    },
    {
      id: 193,
      name: 'Zambian',
    },
    {
      id: 194,
      name: 'Zimbabwean',
    },
  ],
};

export default nationalitiesJSON;
