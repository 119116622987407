const positionsJSON = {
  items: [
    {
      id: 1,
      name: 'Attacking Midfield',
    },
    {
      id: 2,
      name: 'Central Midfield',
    },
    {
      id: 3,
      name: 'Centre Back',
    },
    {
      id: 4,
      name: 'Centre Forward',
    },
    {
      id: 5,
      name: 'Defensive Midfield',
    },
    {
      id: 6,
      name: 'Goalkeeper',
    },
    {
      id: 7,
      name: 'Left Back',
    },
    {
      id: 8,
      name: 'Left Midfield',
    },
    {
      id: 9,
      name: 'Left Wing',
    },
    {
      id: 10,
      name: 'Left Wing Back',
    },
    {
      id: 11,
      name: 'Right Back',
    },
    {
      id: 12,
      name: 'Right Midfield',
    },
    {
      id: 13,
      name: 'Right Wing',
    },
    {
      id: 14,
      name: 'Right Wing Back',
    },
    {
      id: 15,
      name: 'Striker',
    },
    {
      id: 16,
      name: 'Sweeper',
    },
    {
      id: 17,
      name: 'Wing Back',
    },
    {
      id: 18,
      name: 'Referee',
    },
  ],
};

export default positionsJSON;
